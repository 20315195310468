import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import "firebase/functions";
import "firebase/storage";
import "firebase/analytics";
import "firebase/app-check";

import {
  consentVersions,
  isLocal,
  userAccountTypes,
  partnerOrgFromEmail,
  messageAdapter,
  messageAdapterV2,
  accountStatus,
  detectBiriteCompany,
  TRANSACTION_STATUS,
  PAYMENT_METHOD_TYPES,
  plusnWeeks,
  formatBankName,
  isFuturePayment,
  invoiceDisplayType,
} from "../utils/helpers";

import Strings from "../utils/strings";

const config = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_DATABASE_URL,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_APP_ID,
  measurementId: process.env.REACT_APP_MEASUREMENT_ID,
};

//const emulatedUserEmail = "jason@anansii.com";
const emulatedUserEmail = null;

class Firebase {
  constructor() {
    firebase.initializeApp(config);
    this.auth = firebase.auth();
    this.db = firebase.firestore();
    this.firestore = firebase.firestore;
    this.functions = firebase.functions();
    this.storage = firebase.storage();
    this.appcheck = firebase.appCheck();

    const isTestEnvironment =
      process.env.NODE_ENV === "test" || typeof jest !== "undefined";
    if (!isTestEnvironment) this.analytics = firebase.analytics();

    this.appcheck.activate("6LdJhWAkAAAAABrZkyPt7DG42383NlsR449r2-wA", true);

    this.googleProvider = new firebase.auth.GoogleAuthProvider();
    this.yahooProvider = new firebase.auth.OAuthProvider("yahoo.com");

    // if we're on local and we aren't emulating a prod user, use firestore emulator
    if (isLocal() && !emulatedUserEmail) {
      this.db.useEmulator("localhost", 8080);
      this.functions.useEmulator("localhost", 5001);
      this.auth.useEmulator("http://localhost:9099");
      this.storage.useEmulator("localhost", 9199);
    }
  }

  isFirestoreTimestamp = (value) => {
    return value instanceof firebase.firestore.Timestamp;
  };

  uploadInvoice = async (file) => {
    // helpful: https://time2hack.com/upload-files-to-firebase-storage-with-javascript/
    if (this.emulateUser()) return;
    let storageRef = this.storage.ref();
    let uri = "qb_invoices/" + this.auth.currentUser.email + "/" + file.name;
    let uploadTask = storageRef.child(uri).put(file);

    return new Promise((resolve, reject) => {
      uploadTask
        .then((snapshot) => {
          file["url"] = "gs://" + config.storageBucket + "/" + uri;
          resolve(file);
        })
        .catch((error) => {
          switch (error.code) {
            case "storage/unauthorized":
              console.error("User doesn't have permission to upload file");
              reject({ error: "User doesn't have permission to upload file" });
              break;
            case "storage/canceled":
              console.error("User canceled the upload");
              reject({ error: "User canceled the upload" });
              break;
            case "storage/unknown":
              console.error("Unknown error occurred");
              reject({ error: "Unknown error occurred" });
              break;
            default:
              console.error("Unknown error occurred");
              break;
          }
        });
    });
  };

  sendAuthLink = (email, destination) => {
    let actionCodeSettings = {
      handleCodeInApp: true,
      url: "http://" + window.location.host + destination,
    };

    return this.auth.sendSignInLinkToEmail(email, actionCodeSettings);
  };

  signInAnonymously = () => {
    return this.auth.signInAnonymously();
  };

  signInWithLink = (email, url) => {
    return this.auth.signInWithEmailLink(email, url);
  };

  signInWithGoogle = () => {
    return this.auth.signInWithPopup(this.googleProvider);
  };

  signInWithYahoo = () => {
    return this.auth.signInWithPopup(this.yahooProvider);
  };

  signOut = () => this.auth.signOut();

  getUid = () => {
    return this.auth.currentUser && this.auth.currentUser.uid;
  };

  createTimestamp = (jsdate) => {
    return firebase.firestore.Timestamp.fromDate(jsdate);
  };

  getCurrentTimestamp = () => {
    return firebase.firestore.Timestamp.now();
  };

  getCurrentUser = () => {
    return this.auth.currentUser;
  };

  getDisplayName = () => {
    return this.auth.currentUser && this.auth.currentUser.displayName;
  };

  emulateUser = () => {
    if (isLocal() && emulatedUserEmail) {
      //console.log("emulate");
      return true;
    }

    //console.log("don't emulate");

    return false;
  };

  getUserEmail = () => {
    return this.emulateUser()
      ? emulatedUserEmail
      : this.auth.currentUser && this.auth.currentUser.email;
  };

  updateUserEmail = (newEmail) => {
    return this.auth.currentUser.updateEmail(newEmail);
  };

  getUserSignUpDate = () => {
    return Date.parse(this.auth.currentUser.metadata.creationTime);
  };

  getUserLastSignInDate = () => {
    return Date.parse(this.auth.currentUser.metadata.lastSignInTime);
  };

  getUserRole = () => {
    return new Promise((resolve, reject) => {
      this.db
        .collection("users")
        .doc(this.auth.currentUser.email)
        .get()
        .then((doc) => {
          if (!doc.exists) resolve(null);
          resolve(doc.data().accountType);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };

  isAnonUser = () => {
    return this.auth.currentUser.isAnonymous;
  };

  updateDisplayName = (repName) => {
    if (this.emulateUser()) return;
    this.auth.currentUser.updateProfile({ displayName: repName });
    this.db
      .collection("users")
      .doc(this.auth.currentUser.email)
      .update({ name: repName });
  };

  recordNewUser = (email) => {
    this.db
      .collection("users")
      .doc(email)
      .set({ accountType: "" })
      .then(() => {
        console.log("added user");
      })
      .catch((error) => {
        console.error(error);
      });
  };

  recordTermsConsent = (userEmail) => {
    if (this.emulateUser()) return;
    this.db
      .collection("consents")
      .add({
        type: "terms",
        user_email: userEmail,
        version: consentVersions.TERMS,
        effective_date: this.createTimestamp(new Date()),
      })
      .then(() => console.log("terms consent recorded"))
      .catch((e) => console.error("Error with recording consent: " + e));
  };

  recordPrivacyConsent = (userEmail) => {
    if (this.emulateUser()) return;
    this.db
      .collection("consents")
      .add({
        type: "privacy",
        user_email: userEmail,
        version: consentVersions.PRIVACY,
        effective_date: this.createTimestamp(new Date()),
      })
      .then(() => console.log("privacy consent recorded"))
      .catch((e) => console.error("Error with recording consent: " + e));
  };

  updateContactEmail = (userEmail, currEmail, newEmail) => {
    if (this.emulateUser()) return;
    console.log(userEmail);
    console.log(currEmail);
    console.log(newEmail);

    let batch = this.db.batch();

    this.db
      .collection("users")
      .doc(userEmail)
      .collection("qb_invoices")
      .where("BillEmail.Address", "==", currEmail)
      .get()
      .then((docs) => {
        docs.forEach((doc) => {
          console.log(doc.ref);
          batch.update(doc.ref, { BillEmail: { Address: newEmail } });
        });

        batch
          .commit()
          .then(() => {
            console.log("Bill email updated");
          })
          .catch((e) => {
            console.error("Bill email not updated successfully");
          });
      });
  };

  /**
   * Callable Functions
   * ~~~~~~~~~~~~~
   * These wrapper functions simplify creating mocks for testingg
   * */

  getGoogleContacts = () => {
    let func = this.functions.httpsCallable("getGoogleContacts");
    return func();
  };

  getGmailContacts = (userPath) => {
    let func = this.functions.httpsCallable("getGmailContacts");
    return func({ userPath: userPath });
  };

  createDashboardUser = (name, team, termsVersion, privacyVersion) => {
    let func = this.functions.httpsCallable("handleDashboardUserCreated");
    return func({
      email: this.auth.currentUser.email,
      name: name,
      team: team,
      termsVersion: termsVersion,
      privacyVersion: privacyVersion,
      effectiveDate: firebase.firestore.FieldValue.serverTimestamp(),
    });
  };

  createNewSender = (email, name) => {
    const func = this.functions.httpsCallable("sendgrid-createNewSender");
    return func({
      email: email,
      name: name,
    });
  };

  sendPayReminderMsg = (
    toEmails,
    ccEmails,
    message,
    amountDue,
    amountOverdue,
    senderName,
    customerName,
    accountStatus,
    accountId,
    invoiceId,
    clientName,
    attachments,
    msgFlavor = "balances"
  ) => {
    let func = this.functions.httpsCallable("sendgrid-sendPayReminderMsg");
    return func({
      toEmails: toEmails,
      ccEmails: ccEmails,
      message: message,
      amountDue: amountDue,
      amountOverdue: amountOverdue,
      senderName: senderName,
      customerName: customerName,
      accountStatus: accountStatus,
      accountId: accountId,
      invoiceId: invoiceId,
      clientName: clientName,
      attachments: attachments,
      msgFlavor: msgFlavor,
    });
  };

  sendReminderMessage = (data) => {
    let func = this.functions.httpsCallable("sendgrid-sendReminderMessage");
    return func({
      senderName: data.senderName,
      messageType: data.messageType,
      account: data.account,
      message: data.message,
      distributorName: data.distributorName,
      amountDue: data.amountDue,
      amountOverdue: data.amountOverdue,
      from: data.from,
      to: data.to,
      cc: data.cc,
      attachments: data.attachments,
      usePayPortal: data.usePayPortal,
      isPreviewMessage: data.isPreviewMessage,
    });
  };

  verifySender = (senderName, userEmail) => {
    let func = this.functions.httpsCallable("sendgrid-verifySender");
    return func({
      senderName: senderName,
      userEmail: userEmail,
    });
  };

  getBankBalance = (accessToken, accountId, paymentMethodPath) => {
    const func = this.functions.httpsCallable("pay-getBankBalance");
    return func({ accessToken, accountId, paymentMethodPath });
  };

  handleDistrictAgingFileImport = (sheetId) => {
    let func = this.functions.httpsCallable("handleDistrictAgingFile");
    return func({ sheetId });
  };

  getGoogleAuthUri = (type, userPath) => {
    let func = this.functions.httpsCallable("getGoogleAuthUri");
    return func({
      type: type,
      userPath: userPath,
      local: window.location.host.includes("localhost"),
    });
  };

  schedulePaymentsForOpenInvoices = (
    accountPath,
    paymentMethodPath,
    customerName,
    autopayType,
    autopayDay,
    autopayNotifBuffer = 1
  ) => {
    const func = this.functions.httpsCallable(
      "pay-schedulePaymentsForOpenInvoices"
    );
    return func({
      accountPath: accountPath,
      paymentMethodPath: paymentMethodPath,
      customerName: customerName,
      autopayType: autopayType,
      autopayDay: autopayDay,
      autopayNotifBuffer: autopayNotifBuffer,
    });
  };

  fetchEncryptionKey = () => {
    const func = this.functions.httpsCallable("offers-fetchEncryptionKey");
    return func();
  };

  /***
   *
   * Installments Functions
   *
   *
   * */

  execSivoDraw = (loanAmount, customerName, accountNumber) => {
    const func = this.functions.httpsCallable("loan-execSivoDraw");
    return func({
      loanAmount: loanAmount,
      customerName: customerName,
      accountNumber: accountNumber,
    });
  };

  cancelSivoDraw = (loanAmount) => {
    const func = this.functions.httpsCallable("loan-cancelSivoDraw");
    return func({
      loanAmount: loanAmount,
    });
  };

  getAnansiiInstallmentsBankAccount = () => {
    return new Promise((resolve, reject) => {
      const environment = isLocal() ? "sandbox" : "prod";
      console.log("environment is", environment);
      this.db
        .doc("app_config/installments_payment_methods")
        .get()
        .then((doc) => {
          if (!doc.exists) reject(null);
          const data = doc.data();
          const bank = {
            type: "bank",
            institution_name: data[environment].bank_name,
            institution_last4: "****",
            processor_access_token: data[environment].access_token,
            account_id: data[environment].account_id,
            account_name: "Anansii Bank Account",
            id: `pl_${data[environment].account_id}`,
          };
          resolve(bank);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };

  /**
   *
   * Card & ACH Payments
   *
   * */

  execCardPointePayment = (
    token,
    expiry,
    amount,
    surcharge,
    credits,
    accountName,
    accountZip,
    invoiceIds
  ) => {
    const func = this.functions.httpsCallable("pay-execCardPointePayment", {
      timeout: 180000,
    });
    return func({
      token: token,
      expiry: expiry,
      amount: amount,
      surcharge: surcharge,
      credits: credits,
      accountName: accountName,
      accountZip: accountZip,
      invoiceIds: invoiceIds,
    });
  };

  postAssistantMessage = (accountId, threadId, message) => {
    const func = this.functions.httpsCallable("order-postAssistantMessage");
    return func({ accountId: accountId, threadId: threadId, message: message });
  };

  voidCardPointePayment = (transactionId) => {
    const func = this.functions.httpsCallable("pay-voidCardPointePayment");
    return func({
      transactionId: transactionId,
    });
  };

  execPlaidTransferPayment = (
    token,
    amount,
    surcharge,
    credits,
    paymentMethodId,
    payerBusinessName,
    customerName,
    ipAddress,
    userAgent
  ) => {
    const func = this.functions.httpsCallable("pay-execPlaidTransferPayment", {
      timeout: 180000,
    });
    return func({
      accessToken: token,
      amount: amount,
      surcharge: surcharge,
      creditsUsed: credits,
      paymentMethodId: paymentMethodId,
      payerBusinessName: payerBusinessName,
      customerName: customerName,
      ipAddress: ipAddress,
      userAgent: userAgent,
    });
  };

  cancelPlaidTransferPayment = (transactionId, customerName) => {
    const func = this.functions.httpsCallable("pay-cancelPlaidTransferPayment");
    return func({
      transactionId: transactionId,
      customerName: customerName,
    });
  };

  createBankLinkToken = (customerName, accountNumber) => {
    const func = this.functions.httpsCallable("pay-createBankLinkToken");
    return func({
      customerName: customerName,
      accountNumber: accountNumber,
    });
  };

  createBankLinkVerifyToken = (customerName, accountNumber, accessToken) => {
    const func = this.functions.httpsCallable("pay-createBankLinkVerifyToken");
    return func({
      customerName: customerName,
      accountNumber: accountNumber,
      accessToken: accessToken,
    });
  };

  getBankAccessToken = (publicToken) => {
    const func = this.functions.httpsCallable("pay-getBankAccessToken");
    return func({
      publicToken: publicToken,
    });
  };

  getBankAuthData = (accessToken) => {
    const func = this.functions.httpsCallable("pay-getBankAuthData", {
      timeout: 180000,
    });
    return func({
      accessToken: accessToken,
    });
  };

  createBankLinkWithNumbers = (
    accountNumber,
    routingNumber,
    bankAccountType
  ) => {
    const func = this.functions.httpsCallable("pay-createBankLinkWithNumbers");
    return func({
      accountNumber: accountNumber,
      routingNumber: routingNumber,
      bankAccountType: bankAccountType,
    });
  };

  /**
   * Firestore interface functions
   * ~~~~~~~~~~~
   * These wrapper functions simplify creating mocks for testing
   *
   * */

  getCustomers = (callback) => {
    return this.db
      .collection("customers")
      .get()
      .then((snap) => {
        let c;
        let customers = [];
        snap.forEach((doc) => {
          c = doc.data();
          c["id"] = doc.id;
          customers.push(c);
        });
        callback(customers);
      });
  };

  dashboardQueryScope = (selectedRep, selectedCustomer, col) => {
    let userEmail = this.getUserEmail();
    // if selected sales rep is NULL, show logged in user's AR
    if (!selectedRep)
      return this.db.collection("users").doc(userEmail).collection(col);
    // if selected sales rep starts with the "All sales reps..." tag,
    // show all AR for selected organization
    else if (selectedRep.startsWith(Strings.ADMIN_ALL_SELECTION_VALUE))
      return this.db
        .collectionGroup(col)
        .where("distributorName", "==", selectedCustomer);
    // if selected sales rep starts with the "District AR..." tag,
    // show all AR for selected district manager
    else if (selectedRep.startsWith(Strings.ADMIN_MODE_DISTRICT_OPTION)) {
      return this.db
        .collectionGroup(col)
        .where("districtManager", "==", selectedRep.split(": ")[1]);
    }

    // if selected sales rep starts with the "All in district..." tag,
    // show all AR for district manager
    else if (selectedRep === Strings.ADMIN_DISTRICT_SELECTION_VALUE) {
      return this.db
        .collectionGroup(col)
        .where("districtManager", "==", userEmail);
    }

    // otherwise, show the AR for the selected sales rep
    else return this.db.collection("users").doc(selectedRep).collection(col);
  };

  getCustomerInfo = (customerName, callback) => {
    return this.db
      .collection("customers")
      .doc(customerName)
      .get()
      .then((doc) => {
        if (!doc.exists) {
          console.error("customer not found in db");
        } else {
          const c = doc.data();
          c["name"] = doc.id;
          callback(c);
        }
      });
  };

  getAccountsBySalesRepId = (userDetails, ids, customer, callback) => {
    if (userDetails.sub_brand) {
      return this.db
        .collection("customers")
        .doc(customer)
        .collection("accounts")
        .where("salesrep_id", "in", ids)
        .where("sub_brand", "==", userDetails.sub_brand.toUpperCase())
        .onSnapshot((docs) => {
          let a;
          let accounts = [];
          if (docs.size === 0) callback([]);
          else {
            docs.forEach((doc) => {
              a = doc.data();
              a["id"] = doc.id;
              a["path"] = doc.ref.path;
              a["customer"] = customer;
              accounts.push(a);
            });
            callback(accounts);
          }
        });
    }

    return this.db
      .collection("customers")
      .doc(customer)
      .collection("accounts")
      .where("salesrep_id", "in", ids)
      .onSnapshot((docs) => {
        let a;
        let accounts = [];
        if (docs.size === 0) callback([]);
        else {
          docs.forEach((doc) => {
            a = doc.data();
            a["id"] = doc.id;
            a["path"] = doc.ref.path;
            a["customer"] = customer;
            accounts.push(a);
          });
          callback(accounts);
        }
      });
  };

  getAccountsByDistrictId = (userDetails, id, customer, callback) => {
    if (userDetails.sub_brand) {
      return this.db
        .collection("customers")
        .doc(customer)
        .collection("accounts")
        .where("district_id", "==", id)
        .where("sub_brand", "==", userDetails.sub_brand.toUpperCase())
        .onSnapshot((docs) => {
          let a;
          let accounts = [];
          if (docs.size === 0) callback([]);
          else {
            docs.forEach((doc) => {
              a = doc.data();
              a["id"] = doc.id;
              a["path"] = doc.ref.path;
              a["customer"] = customer;
              accounts.push(a);
            });
            callback(accounts);
          }
        });
    }

    return this.db
      .collection("customers")
      .doc(customer)
      .collection("accounts")
      .where("district_id", "==", id)
      .onSnapshot((docs) => {
        let a;
        let accounts = [];
        if (docs.size === 0) callback([]);
        else {
          docs.forEach((doc) => {
            a = doc.data();
            a["id"] = doc.id;
            a["path"] = doc.ref.path;
            a["customer"] = customer;
            accounts.push(a);
          });
          callback(accounts);
        }
      });
  };

  getAccountsByCustomer = (userDetails, customer, callback) => {
    if (userDetails.sub_brand) {
      return this.db
        .collection("customers")
        .doc(customer)
        .collection("accounts")
        .where("sub_brand", "==", userDetails.sub_brand.toUpperCase())
        .onSnapshot((docs) => {
          let a;
          let accounts = [];
          if (docs.size === 0) callback([]);
          else {
            docs.forEach((doc) => {
              a = doc.data();
              a["id"] = doc.id;
              a["path"] = doc.ref.path;
              a["customer"] = customer;
              accounts.push(a);
            });
            callback(accounts);
          }
        });
    }

    return this.db
      .collection("customers")
      .doc(customer)
      .collection("accounts")
      .onSnapshot((docs) => {
        let a;
        let accounts = [];
        if (docs.size === 0) callback([]);
        else {
          docs.forEach((doc) => {
            a = doc.data();
            a["id"] = doc.id;
            a["path"] = doc.ref.path;
            a["customer"] = customer;
            accounts.push(a);
          });
          callback(accounts);
        }
      });
  };

  getDistrictsByCustomer = (userDetails, customer, callback) => {
    return this.getAccountsByCustomer(userDetails, customer, (accounts) => {
      const districts = [...new Set(accounts.map((a) => a.district_id))];
      callback(districts.sort());
    });
  };

  prepBalances = (doc) => {
    const balance = doc.data();
    return {
      ...balance,
      milliseconds: balance.created.toMillis(),
      account_id: doc.ref.parent.parent.id,
    };
  };

  getBalancesBySalesRepId = (id, callback) => {
    return this.db
      .collectionGroup("balances")
      .where("cqk_salesrep_id", "==", id)
      .orderBy("created", "asc")
      .get()
      .then((docs) => {
        let b;
        let balances = [];
        if (docs.size === 0) callback([]);
        else {
          docs.forEach((doc) => {
            b = this.prepBalances(doc);
            balances.push(b);
          });
          callback(balances);
        }
      });
  };

  getBalancesByDistrictId = (id, callback) => {
    return this.db
      .collectionGroup("balances")
      .where("cqk_district_id", "==", id)
      .orderBy("created", "asc")
      .get()
      .then((docs) => {
        let b;
        let balances = [];
        if (docs.size === 0) callback([]);
        else {
          docs.forEach((doc) => {
            b = this.prepBalances(doc);
            balances.push(b);
          });
          callback(balances);
        }
      });
  };

  getBalancesByCustomer = (customer, callback) => {
    return this.db
      .collectionGroup("balances")
      .where("cqk_customer", "==", customer)
      .orderBy("created", "asc")
      .get()
      .then((docs) => {
        let b;
        let balances = [];
        if (docs.size === 0) callback([]);
        else {
          docs.forEach((doc) => {
            b = this.prepBalances(doc);
            balances.push(b);
          });
          callback(balances);
        }
      });
  };

  getAccounts = (selectedRep, selectedCustomer, callback) => {
    let accounts = [];
    let d;

    let q = this.dashboardQueryScope(
      selectedRep,
      selectedCustomer,
      "qb_invoices"
    );
    return q.onSnapshot((snap) => {
      snap.forEach((doc) => {
        d = doc.data();
        d["Name"] = d.CustomerRef.name;
        d["Status"] = accountStatus(d);
        d["DocRef"] = doc.ref.path;
        accounts.push(d);
      });

      callback(accounts);
    });
  };

  getAllMessagesFromDistributor = (distributor, callback) => {
    return this.db
      .collectionGroup("messages")
      .where("distributorName", "==", distributor)
      .onSnapshot((snaps) => {
        let messages = [];
        snaps.forEach((snap) => {
          messages.push(messageAdapter(snap.data()));
        });
        callback(messages);
      });
  };

  getMessagesByCustomer = (customerName, callback) => {
    return this.db
      .collectionGroup("messages")
      .where("cqk_customer", "==", customerName)
      .orderBy("sent", "desc")
      .onSnapshot((docs) => {
        let messages = [];
        let m;
        docs.forEach((doc) => {
          m = doc.data();
          m["id"] = doc.ref.id;
          messages.push(messageAdapterV2(m));
        });
        callback(messages);
      });
  };

  getUserInfo = (callback) => {
    let userEmail = this.getUserEmail();

    return this.db
      .collection("users")
      .doc(userEmail)
      .onSnapshot((doc) => {
        if (!doc.exists) {
          console.error("user email not found in firestore");
        } else {
          let userObj = doc.data();
          userObj["email"] = doc.id;
          callback(userObj);
        }
      });
  };

  getUser = (customerName, callback) => {
    const userEmail = this.getUserEmail();
    let u, doc;
    return this.db
      .collection(`customers/${customerName}/users`)
      .where("email", "==", userEmail)
      .get()
      .then((snap) => {
        if (snap.empty) {
          console.error("user email not found", userEmail, customerName);
          callback(undefined);
        } else if (snap.size > 1) {
          throw new Error("found multiple users with that same email");
        } else {
          doc = snap.docs[0];
          u = doc.data();
          u["path"] = doc.ref.path;
          callback(u);
        }
      });
  };

  getGoogleAuthTokens = (userPath, callback) => {
    return this.db
      .doc(userPath)
      .collection("tkns")
      .doc("google")
      .onSnapshot((doc) => {
        if (!doc.exists) {
          console.error("tokens not found");
          callback(undefined);
        } else {
          callback(doc.data());
        }
      });
  };

  getDistrictManagersForOrg = (org, callback) => {
    let managers = [];

    return this.db
      .collection("users")
      .where("accountType", "in", [
        userAccountTypes.DISTRICT_MANAGER,
        userAccountTypes.CUSTOMER_ADMIN,
        userAccountTypes.ANANSII_ADMIN,
      ])
      .get()
      .then((snap) => {
        snap.forEach((doc) => {
          if (doc.id.includes(org)) managers.push(doc.id);
        });
        callback(managers);
      });
  };

  getAccountById = async (customer, accountId, callback) => {
    console.log(customer, accountId);

    this.db
      .collection("customers")
      .doc(customer)
      .collection("accounts")
      .doc(accountId)
      .onSnapshot((snap) => {
        let a;
        // if accounts were found by id (more than zero),
        // use callback
        //console.log(snap.exists);
        if (snap.exists) {
          console.log("account found by id");
          a = snap.data();
          a["id"] = snap.id;
          a["path"] = snap.ref.path;
          a["customer"] = customer;
          callback(a);
        } else {
          // if accounts were not found by id,
          // search by emails
          console.log("account not found by id");
          callback(null);
        }
      });
  };

  getAccountsByPayerEmail = async (email, callback) => {
    if (!email) return callback(null, email);

    return this.db
      .collectionGroup("accounts")
      .where("payers", "array-contains", email.toLowerCase())
      .onSnapshot((snap) => {
        let a;
        let accounts = [];
        if (snap.size > 0) {
          console.log("account found by email", snap.size);
          snap.forEach((doc) => {
            a = doc.data();
            a["id"] = doc.id;
            a["path"] = doc.ref.path;
            a["customer"] = doc.ref.parent.parent.id;
            accounts.push(a);
          });
          //console.log(accounts);
          callback(accounts, email);
        } else {
          console.log("account not found by email");
          callback(null, email);
        }
      });
  };

  /***
   *
   * Cushion
   *
   * */

  unpackQueryResults = (snapshot) => {
    let item;
    let items = [];

    snapshot.forEach((docRef) => {
      item = docRef.data();
      item["id"] = docRef.id;
      item["path"] = docRef.ref.path;
      items.push(item);
    });

    return items;
  };

  getCustomerAccountsByUserEmail = (email, callback) => {
    if (!email) return;

    return this.db
      .collectionGroup("accounts")
      .where("payers", "array-contains", email.toLowerCase())
      .get()
      .then((snap) => {
        if (snap.empty) {
          callback([]);
        } else {
          const accounts = this.unpackQueryResults(snap);
          callback(accounts);
        }
      });
  };

  getBusinessByCustomerAccount = (account, callback) => {
    const customerAccountPath = account.path;

    return this.db
      .collection("businesses")
      .where("customer_account_path", "==", customerAccountPath)
      .onSnapshot((snap) => {
        if (snap.empty) {
          callback(null);
        } else {
          const businesses = this.unpackQueryResults(snap);
          callback(businesses[0]);
        }
      });
  };

  createBusinessProfileFromCustomerAccount = (account, userEmail) => {
    const pathArr = account.path.split("/");
    const customerKey = pathArr[1];
    const businessDocId = `${customerKey}_${account.id}`;

    const data = {
      name: account.name,
      authed_emails: [userEmail],
      established_on: null,
      notify_emails: [userEmail],
      owners: null,
      phone: account.phone,
      billing_address_one: account.billing_address_one,
      billing_address_two: account.billing_address_two,
      billing_city: account.billing_city,
      billing_state: account.billing_state,
      billing_zip: account.billing_zip,
      location_address_one: account.shipping_address_one,
      location_address_two: account.shipping_address_two,
      location_city: account.shipping_city,
      location_state: account.shipping_state,
      location_zip: account.shipping_zip,
      customer_account_path: account.path,
    };
    this.firestoreSet(`/businesses/${businessDocId}`, data);
  };

  firestoreBatchSet = async (items, collectionpath) => {
    if (this.emulateUser()) return;
    let count = 0;
    let records = 0;

    let batch = this.db.batch();
    let copy = items.slice();
    let ref, item;
    while (copy.length) {
      item = copy.shift();
      if (item.id) ref = this.db.collection(collectionpath).doc(item.id);
      else ref = this.db.collection(collectionpath).doc();
      batch.set(ref, item, { merge: true });
      if (++count >= 500 || !copy.length) {
        await batch.commit();
        records += count;
        batch = this.db.batch();
        count = 0;
      }
    }
  };

  updateBusinessInfo = (businessInfo, ownerInfo, bankMethod) => {
    const businessInfoData = {
      id: businessInfo.id,
      name: businessInfo.businessName,
      dba: businessInfo.dba,
      billing_address_one: businessInfo.addressLine1,
      billing_address_two: businessInfo.addressLine2,
      billing_city: businessInfo.city,
      billing_state: businessInfo.state,
      billing_zip: businessInfo.zipcode,
      established_on: businessInfo.establishedOn,
    };
    const owners = [
      {
        first_name: ownerInfo.firstName,
        last_name: ownerInfo.lastName,
        email: ownerInfo.email,
        home_address_one: ownerInfo.ownerAddressLine1,
        home_address_two: ownerInfo.ownerAddressLine2,
        home_city: ownerInfo.ownerCity,
        home_state: ownerInfo.ownerState,
        home_zip: ownerInfo.ownerZipcode,
        dob: ownerInfo.dateOfBirth,
        ssn_last_four: ownerInfo.ssnLast4,
        softpull_consent_on: this.firestore.Timestamp.now(),
        ssn: ownerInfo.fullSSN,
      },
    ];

    this.firestoreUpdate(businessInfo.path, {
      ...businessInfoData,
      owners: owners,
    });

    this.firestoreUpdate(bankMethod.path, {
      last_used: this.firestore.FieldValue.serverTimestamp(),
    });
  };

  recordServiceApplication = (
    businessInfo,
    ownerInfo,
    bankMethod,
    userEmail,
    serviceName
  ) => {
    const data = {
      provider: serviceName,
      status: "created",
      contact_first_name: ownerInfo.firstName,
      contact_last_name: ownerInfo.lastName,
      contact_email: userEmail,
      business_path: businessInfo.path,
      bank_account_path: bankMethod.path,
      created: this.firestore.FieldValue.serverTimestamp(),
    };
    const path = `${businessInfo.path}/services`;

    return this.firestoreAdd(path, data);
  };

  recordFundboxServiceApplication = (
    businessInfo,
    ownerInfo,
    bankMethod,
    userEmail
  ) => {
    return this.recordServiceApplication(
      businessInfo,
      ownerInfo,
      bankMethod,
      userEmail,
      "fundbox"
    );
  };

  getPaymentMethodsFromAccountPath = (accountPath, callback) => {
    return this.db
      .doc(accountPath)
      .collection("payment_methods")
      .orderBy("last_used", "desc")
      .onSnapshot((snap) => {
        if (snap.empty) {
          callback([]);
        } else {
          const paymentMethods = this.unpackQueryResults(snap);
          callback(paymentMethods);
        }
      });
  };

  syncBusinessFundboxApplications = (businessPath, callback) => {
    return this.db
      .doc(businessPath)
      .collection("services")
      .where("provider", "==", "fundbox")
      .orderBy("created", "desc")
      .onSnapshot((snap) => {
        if (snap.empty) callback(undefined);
        else {
          const services = this.unpackQueryResults(snap);
          callback(services[0]);
        }
      });
  };

  /****/

  getInvoicesByPayerId = (payerId, callback) => {
    return this.db
      .collectionGroup("invoices")
      .where("payer_id", "==", payerId)
      .orderBy("due_date", "asc")
      .onSnapshot((docs) => {
        if (docs.size === 0) callback([]);
        else {
          let i;
          let invoices = [];
          docs.forEach((doc) => {
            i = doc.data();
            i["id"] = doc.id;
            i["path"] = doc.ref.path;
            invoices.push(i);
          });
          callback(invoices);
        }
      });
  };

  getInvoicesByAccountId = (accountId, callback) => {
    return this.db
      .collectionGroup("invoices")
      .where("account_id", "==", accountId)
      .orderBy("due_date", "asc")
      .onSnapshot((docs) => {
        if (docs.size === 0) callback([]);
        else {
          let i;
          let invoices = [];
          docs.forEach((doc) => {
            i = doc.data();
            i["id"] = doc.id;
            i["path"] = doc.ref.path;
            invoices.push(i);
          });
          callback(invoices);
        }
      });
  };

  getInvoicesByPayerIdOnce = (payerId, callback) => {
    return this.db
      .collectionGroup("invoices")
      .where("payer_id", "==", payerId)
      .orderBy("due_date", "asc")
      .get()
      .then((docs) => {
        if (docs.size === 0) callback([]);
        else {
          let i;
          let invoices = [];
          docs.forEach((doc) => {
            i = doc.data();
            i["id"] = doc.id;
            i["path"] = doc.ref.path;
            invoices.push(i);
          });
          callback(invoices);
        }
      });
  };

  getInvoicesByAccountIdOnce = (accountId, callback) => {
    return this.db
      .collectionGroup("invoices")
      .where("account_id", "==", accountId)
      .orderBy("due_date", "asc")
      .get()
      .then((docs) => {
        if (docs.size === 0) callback([]);
        else {
          let i;
          let invoices = [];
          docs.forEach((doc) => {
            i = doc.data();
            i["id"] = doc.id;
            i["path"] = doc.ref.path;
            invoices.push(i);
          });
          callback(invoices);
        }
      });
  };

  getInvoiceById = (invoiceId, callback) => {
    return this.db
      .collectionGroup("invoices")
      .where("id", "==", invoiceId)
      .get()
      .then((snap) => {
        if (snap.empty) callback(null);
        else {
          const doc = snap.docs[0];
          const inv = doc.data();
          inv["id"] = doc.id;
          inv["path"] = doc.ref.path;
          callback(inv);
        }
      });
  };

  getInvoicesById = (invIds, callback) => {
    const copy = invIds.slice();
    const arrs = [];
    while (copy.length) {
      arrs.push(copy.splice(0, 10));
    }

    //console.log(arrs);
    const promises = arrs.map((arr) => this.getTenInvoicesById(arr));
    return Promise.all(promises).then((arrs) => callback(arrs.flat()));
  };

  getTenInvoicesById = async (invIds) => {
    return new Promise((resolve, reject) => {
      this.db
        .collectionGroup("invoices")
        .where("id", "in", invIds)
        .get()
        .then((snap) => {
          if (snap.empty) resolve([]);
          else {
            let i;
            let invoices = [];
            snap.forEach((doc) => {
              i = doc.data();
              i["id"] = doc.id;
              i["path"] = doc.ref.path;
              invoices.push(i);
            });
            resolve(invoices);
          }
        })
        .catch((err) => {
          reject([]);
        });
    });
  };

  getMessageEventsBySendGridId = (messages, callback) => {
    const sendGridMessageIds = messages
      .map((m) => m.sendgrid_message_id)
      .filter((id) => typeof id === "string");
    const arrs = [];
    while (sendGridMessageIds.length) {
      arrs.push(sendGridMessageIds.splice(0, 10));
    }

    const promises = arrs.map((arr) =>
      this.getTenMessageEventsBySendGridId(arr)
    );
    return Promise.all(promises).then((arrs) => callback(arrs.flat()));
  };

  getTenMessageEventsBySendGridId = async (sgMessageIds) => {
    return new Promise((resolve, reject) => {
      this.db
        .collectionGroup("message_events")
        .where("sendgrid_message_id", "in", sgMessageIds)
        .get()
        .then((snap) => {
          if (snap.empty) resolve([]);
          else {
            let e;
            let messageEvents = [];
            snap.forEach((doc) => {
              e = doc.data();
              e["id"] = doc.id;
              e["path"] = doc.ref.path;
              messageEvents.push(e);
            });
            resolve(messageEvents);
          }
        })
        .catch((err) => {
          reject([]);
        });
    });
  };

  getOpenInvoicesByAccount = (account, callback) => {
    return this.db
      .doc(account.path)
      .collection("invoices")
      .where("status", "in", ["OPEN", "PENDING", "open", "pending"])
      .orderBy("due_date", "asc")
      .get()
      .then((snap) => {
        if (snap.size === 0) callback([]);
        else {
          let i;
          let invoices = [];
          snap.forEach((doc) => {
            i = doc.data();
            i["id"] = doc.id;
            i["path"] = doc.ref.path;
            invoices.push(i);
          });
          callback(invoices);
        }
      });
  };

  getPaidInvoicesByAccount = (account, callback, daysOfHistory = 90) => {
    let today = new Date();
    let earliestDate = new Date();
    earliestDate.setDate(today.getDate() - daysOfHistory);

    let earliestTimestamp = this.firestore.Timestamp.fromDate(earliestDate);

    return this.db
      .doc(account.path)
      .collection("invoices")
      .where("status", "in", ["PAID", "paid"])
      .where("order_date", ">=", earliestTimestamp)
      .orderBy("order_date", "desc")
      .get()
      .then((snap) => {
        if (snap.size === 0) callback([]);
        else {
          let i;
          let invoices = [];
          snap.forEach((doc) => {
            i = doc.data();
            i["id"] = doc.id;
            i["path"] = doc.ref.path;
            invoices.push(i);
          });
          //console.log(invoices);
          callback(invoices);
        }
      });
  };

  getRecentOpenInvoicesByCustomer = (customerName, callback, limit = 1000) => {
    return this.db
      .collectionGroup("invoices")
      .where("type", "==", "INVOICE")
      .where("cqk_customer", "==", customerName)
      .where("status", "in", ["OPEN", "open"])
      .orderBy("order_date", "asc")
      .limit(limit)
      .onSnapshot((snap) => {
        if (snap.size === 0) callback([]);
        else {
          let i;
          let invoices = [];
          snap.forEach((doc) => {
            i = doc.data();
            i["id"] = doc.id;
            i["path"] = doc.ref.path;
            invoices.push(i);
          });
          callback(invoices);
        }
      });
  };

  getAccountByPath = (path, callback) => {
    return this.db
      .doc(path)
      .get()
      .then((doc) => {
        if (!doc.exists) callback(null);
        else {
          let a = doc.data();
          a["id"] = doc.id;
          a["path"] = doc.ref.path;
          a["customer"] = doc.ref.parent.parent.id;
          callback(a);
        }
      });
  };

  getInvoiceByPath = (path, callback) => {
    return this.getObjectByPath(path, callback);
  };

  getPaymentByPath = (path, callback) => {
    return this.getObjectByPath(path, callback);
  };

  getObjectByPath = (path, callback) => {
    return this.db
      .doc(path)
      .get()
      .then((doc) => {
        if (!doc.exists) callback(null);
        else {
          let o = doc.data();
          o["id"] = doc.id;
          o["path"] = doc.ref.path;
          callback(o);
        }
      });
  };

  getPaymentMethodsByAccount = (account, callback) => {
    return this.db
      .doc(account.path)
      .collection("payment_methods")
      .orderBy("last_used", "desc")
      .onSnapshot((snap) => {
        console.log("getting payment methods", snap.size);
        if (snap.size === 0) callback([]);
        else {
          let m;
          let methods = [];
          snap.forEach((doc) => {
            m = doc.data();
            m["id"] = doc.id;
            m["path"] = doc.ref.path;
            methods.push(m);
          });
          callback(methods);
        }
      });
  };

  getPaymentMethodsByCustomer = (customerName, callback) => {
    return this.db
      .collectionGroup("payment_methods")
      .where("cqk_customer", "==", customerName)
      .orderBy("last_used", "desc")
      .onSnapshot((snap) => {
        if (snap.size === 0) callback([]);
        else {
          let m;
          let methods = [];
          snap.forEach((doc) => {
            m = doc.data();
            m["id"] = doc.id;
            m["path"] = doc.ref.path;
            methods.push(m);
          });
          callback(methods);
        }
      });
  };

  getPaymentsByPayerId = (payerId, customer, callback) => {
    return this.db
      .collectionGroup("payments")
      .where("cqk_customer", "==", customer)
      .where("payer_id", "==", payerId)
      .orderBy("created", "desc")
      .onSnapshot((snap) => {
        if (snap.empty) callback([]);
        else {
          let p;
          let payments = [];
          snap.forEach((doc) => {
            p = doc.data();
            p["id"] = doc.id;
            p["path"] = doc.ref.path;
            payments.push(p);
          });
          callback(payments);
        }
      });
  };

  getLoanPaymentsByPayerId = (payerId, customer, callback) => {
    return this.db
      .collectionGroup("loan_payments")
      .where("cqk_customer", "==", customer)
      .where("payer_id", "==", payerId)
      .orderBy("created", "desc")
      .onSnapshot((snap) => {
        if (snap.empty) callback([]);
        else {
          let p;
          let loanpayments = [];
          snap.forEach((doc) => {
            p = doc.data();
            p["id"] = doc.id;
            p["path"] = doc.ref.path;
            loanpayments.push(p);
          });
          callback(loanpayments);
        }
      });
  };

  getPaymentsByCustomer = (customerName, callback) => {
    return this.db
      .collectionGroup("payments")
      .where("cqk_customer", "==", customerName)
      .orderBy("last_used", "desc")
      .onSnapshot((snap) => {
        if (snap.size === 0) callback([]);
        else {
          let payment;
          let payments = [];
          snap.forEach((doc) => {
            payment = doc.data();
            payment["id"] = doc.id;
            payment["path"] = doc.ref.path;
            payments.push(payment);
          });
          callback(payments);
        }
      });
  };

  getPaymentsByAccount = (account, callback) => {
    return this.db
      .collection(`${account.path}/payments`)
      .where("method_type", "not-in", ["cash", "CASH"])
      .onSnapshot((snap) => {
        if (snap.size === 0) callback([]);
        else {
          let p;
          let payments = [];
          snap.forEach((doc) => {
            p = doc.data();
            p["id"] = doc.ref.id;
            p["path"] = doc.ref.path;
            payments.push(p);
          });
          callback(payments);
        }
      });
  };

  getLoansByAccount = (account, callback) => {
    return this.db.collection(`${account.path}/loans`).onSnapshot((snap) => {
      if (snap.size === 0) callback([]);
      else {
        let l;
        let loans = [];
        snap.forEach((doc) => {
          l = doc.data();
          l["id"] = doc.ref.id;
          l["path"] = doc.ref.path;
          loans.push(l);
        });
        callback(loans);
      }
    });
  };

  getOrphanedPaymentsByCustomer = (customerName, callback) => {
    return this.db
      .collection(`/customers/${customerName}/orphaned_payments`)
      .where("archived", "==", false)
      .onSnapshot((snap) => {
        if (snap.size === 0) callback([]);
        else {
          let p;
          let payments = [];
          snap.forEach((doc) => {
            p = doc.data();
            p["id"] = doc.ref.id;
            p["path"] = doc.ref.path;
            payments.push(p);
          });
          callback(payments);
        }
      });
  };

  getExceptionByCheckNumber = (customerName, checkNumber, callback) => {
    return this.db
      .collection(`/customers/${customerName}/orphaned_payments`)
      .where("check_num", "==", checkNumber)
      .get()
      .then((snap) => {
        if (snap.size === 0) callback([]);
        else {
          let p;
          let payments = [];
          snap.forEach((doc) => {
            p = doc.data();
            p["id"] = doc.ref.id;
            p["path"] = doc.ref.path;
            payments.push(p);
          });
          callback(payments);
        }
      });
  };

  getUnresolvedArchivedExceptionsByCustomer = (customerName, callback) => {
    return this.db
      .collection(`/customers/${customerName}/orphaned_payments`)
      .where("archived", "==", true)
      .where("resolve_path", "==", null)
      .onSnapshot((snap) => {
        if (snap.size === 0) callback([]);
        else {
          let p;
          let payments = [];
          snap.forEach((doc) => {
            p = doc.data();
            p["id"] = doc.ref.id;
            p["path"] = doc.ref.path;
            payments.push(p);
          });
          callback(payments);
        }
      });
  };

  getResolvedExceptionPayment = (resolvePath) => {
    return new Promise((resolve, reject) => {
      this.firestoreGet(resolvePath)
        .then((doc) => {
          if (!doc.exists) reject("could not find payment");
          else {
            let p = doc.data();
            p["id"] = doc.ref.id;
            p["path"] = doc.ref.path;
            resolve(p);
          }
        })
        .catch((error) => {
          console.log("error getting document: ", error);
          reject(null);
        });
    });
  };

  getResolvedExceptionPayments = (resolvePaths, callback) => {
    const copy = resolvePaths.slice();

    const promises = [];
    for (let path of resolvePaths) {
      promises.push(this.getResolvedExceptionPayment(path));
    }

    return Promise.all(promises)
      .then((arr) => callback(arr))
      .catch((err) => callback([]));
  };

  turnAutopayOnForAccount = (
    accountPath,
    autopayMethodPath,
    autopayType,
    autopayDay,
    autopayNotificationBuffer = 1
  ) => {
    return this.firestoreUpdate(accountPath, {
      isAutopayEnrolled: true,
      autopayMethodPath: autopayMethodPath,
      autopay_type: autopayType,
      autopay_day: autopayType === "weekly" ? autopayDay : null,
      autopay_notify_buffer: autopayNotificationBuffer,
    });
  };

  turnAutopayOffForAccount = (accountPath) => {
    return this.firestoreUpdate(accountPath, {
      isAutopayEnrolled: false,
      autopayMethodPath: null,
      autopay_type: null,
      autopay_day: null,
      autopay_notify_buffer: null,
    });
  };

  addPayerEmailToAccount = (accountPath, newPayerEmails) => {
    return this.firestoreUpdate(accountPath, {
      payers: firebase.firestore.FieldValue.arrayUnion(...newPayerEmails),
    });
  };

  recordNewPayerEmails = (account, newPayerEmails) => {
    if (!account.payers || typeof account.payers === undefined)
      return this.firestoreUpdate(account.path, {
        payers: newPayerEmails,
      });

    return this.firestoreUpdate(account.path, {
      payers: firebase.firestore.FieldValue.arrayUnion(...newPayerEmails),
    });
  };

  recordNewCardPaymentMethod = (
    account,
    token,
    brand,
    exp_month,
    exp_year,
    last4
  ) => {
    return this.firestoreSet(`${account.path}/payment_methods/cp_${token}`, {
      type: "card",
      status: "succeeded",
      cqk_account_id: account.id,
      cqk_customer: account.customer,
      processor_access_token: token,
      brand: brand,
      exp_month: exp_month,
      exp_year: exp_year,
      last4: last4,
      method_name: `${brand.toUpperCase()} - ${last4}`,
      last_used: this.firestore.FieldValue.serverTimestamp(),
      created: this.firestore.FieldValue.serverTimestamp(),
      archived: false,
    });
  };

  recordNewBankPaymentMethod = (
    account,
    metadata,
    accessToken,
    bankItemId,
    contactEmail
  ) => {
    const data = {
      id: bankItemId,
      type: "bank",
      status: metadata.accounts[0].verification_status || "succeeded",
      processor_access_token: accessToken,
      institution_name: metadata.institution.name || null,
      institution_last4: metadata.accounts[0].mask,
      account_id: metadata.accounts[0].id || metadata.accounts[0].account_id,
      account_name: metadata.accounts[0].name,
      account_type: metadata.accounts[0].type,
      account_subtype: metadata.accounts[0].subtype,
      account_number: "",
      routing_number: metadata.routingNumber,
      last_used: this.firestore.FieldValue.serverTimestamp(),
      created: this.firestore.FieldValue.serverTimestamp(),
      cqk_account_id: account.id,
      cqk_customer: account.customer,
      payer_name: account.name,
      contact_email: contactEmail,
      balance: null,
      archived: false,
    };

    return this.firestoreSet(
      `${account.path}/payment_methods/pl_${bankItemId}`,
      data
    );
  };

  firestoreDoc = (collectionPath) => {
    return this.db.collection(collectionPath).doc();
  };

  firestoreGet = (path) => {
    return this.db.doc(path).get();
  };

  firestoreAdd = (collectionPath, data) => {
    return this.db.collection(collectionPath).add(data);
  };

  firestoreSet = (documentPath, data) => {
    return this.db.doc(documentPath).set(data, { merge: true });
  };

  firestoreUpdate = (documentPath, data) => {
    return this.db.doc(documentPath).update(data);
  };

  firestoreDelete = (documentPath) => {
    return this.db.doc(documentPath).delete();
  };

  firestoreDocExists = async (path) => {
    return new Promise((resolve, reject) => {
      this.firestoreGet(path)
        .then((doc) => {
          if (doc.exists) resolve(true);
          else resolve(false);
        })
        .catch((err) => {
          console.error("something went wrong", err);
          reject(err);
        });
    });
  };

  recordNewInstallmentLoan = (
    customerName,
    accountNumber,
    loanId,
    term,
    interestRate,
    loanPrincipal,
    loanFee,
    installmentSeries,
    selectedInvoices
  ) => {
    // console.log(
    //   customerName,
    //   accountNumber,
    //   loanId,
    //   term,
    //   interestRate,
    //   loanPrincipal,
    //   loanFee,
    //   installmentSeries,
    //   selectedInvoices,
    //   sivoDrawId
    // );
    const paidInvoices = selectedInvoices.map((i, index) => ({
      id: i.id,
      path: i.path,
      amount: i.paymentAmount,
      account_id: i.account_id,
      index: index + 1,
      prefix: invoiceDisplayType(i.type.toUpperCase()),
    }));

    const dueDate = plusnWeeks(new Date(), term - 1);
    const prepped = installmentSeries.map((p) => ({
      ...p,
      date: this.createTimestamp(p.date),
    }));

    const data = {
      id: loanId,
      type: "installment",
      term: term,
      interest_rate: interestRate,
      principal_amount: Math.round(loanPrincipal),
      interest_amount: Math.round(loanFee),
      balance: Math.round(loanPrincipal + loanFee),
      installment_series: prepped,
      status: "open",
      created: this.firestore.FieldValue.serverTimestamp(),
      updated: this.firestore.FieldValue.serverTimestamp(),
      completed: null,
      paid_invoices: paidInvoices,
      due: this.createTimestamp(dueDate),
      cqk_account_id: accountNumber,
    };

    const path = `customers/${customerName}/accounts/${accountNumber}/loans/${loanId}`;
    this.firestoreSet(path, data);
  };

  updateInstallmentLoanStatus = (newStatus, loanPath) => {
    const data = {
      status: newStatus,
    };

    this.firestoreSet(loanPath, data);
  };

  cancelInstallmentLoan = (customerName, accountNumber, loanId) => {
    const path = `customers/${customerName}/accounts/${accountNumber}/loans/${loanId}`;
    this.updateInstallmentLoanStatus("cancelled", path);
  };

  recordNewPayment = (
    payerId,
    payerName,
    payerEmail,
    payerZip,
    notifyEmails,
    paymentMethodId,
    paymentMethodName,
    paymentMethodType,
    processorPaymentId,
    processorAccessToken,
    status,
    customerName,
    selectedInvoices,
    paymentAmount,
    paymentSurcharge,
    paymentCredits,
    paymentDate,
    expiry,
    ipAddress,
    userAgent,
    addl = {}
  ) => {
    const paidInvoices = selectedInvoices.map((i, index) => ({
      id: i.id,
      path: i.path,
      amount: Math.round(i.paymentAmount),
      account_id: i.account_id,
      index: index + 1,
      prefix: invoiceDisplayType(i.type.toUpperCase()),
    }));

    const deduped = [...new Set(notifyEmails)];
    const isScheduledPayment = isFuturePayment(paymentDate);

    const data = {
      company: detectBiriteCompany(payerId),
      division: "1",
      department: "1",
      check_num: "",
      payer_id: payerId,
      payer_name: payerName,
      payer_email: payerEmail,
      payer_zip: payerZip,
      notify_emails: deduped,
      method_id: paymentMethodId,
      method_name: paymentMethodName,
      method_type: paymentMethodType,
      processor_payment_id: processorPaymentId,
      processor_access_token: processorAccessToken,
      status: status,
      cqk_customer: customerName,
      paid_invoices: paidInvoices,
      amount: Math.round(paymentAmount),
      surcharge: Math.round(paymentSurcharge),
      credits_used: Math.abs(Math.round(paymentCredits)),
      created: this.firestore.FieldValue.serverTimestamp(),
      scheduled: isScheduledPayment
        ? this.firestore.Timestamp.fromDate(paymentDate)
        : null,
      processed: isScheduledPayment
        ? this.firestore.Timestamp.fromDate(paymentDate)
        : this.firestore.FieldValue.serverTimestamp(),
      completed:
        status === TRANSACTION_STATUS.SUCCESS ||
        status === TRANSACTION_STATUS.FAILURE ||
        status === TRANSACTION_STATUS.CLOSED
          ? this.firestore.FieldValue.serverTimestamp()
          : null,
      error: null,
      expiry: expiry,
      ip_address: ipAddress,
      user_agent: userAgent,
      ...addl,
    };

    const path = `customers/${customerName}/accounts/${payerId}/payments`;

    this.firestoreAdd(path, data);
  };

  recordNewBankPayment = (
    payerId,
    payerName,
    payerEmail,
    notifyEmails,
    bankMethod,
    paymentMethodId,
    processorPaymentId,
    processorAccessToken,
    status,
    customerName,
    selectedInvoices,
    paymentAmount,
    paymentSurcharge,
    paymentCredits,
    paymentDate,
    ipAddress,
    userAgent,
    addl = {}
  ) => {
    const paymentMethodName = formatBankName(bankMethod);
    const paymentMethodType = PAYMENT_METHOD_TYPES.BANK;
    const expiry = null;
    const payerZip = null;

    this.recordNewPayment(
      payerId,
      payerName,
      payerEmail,
      payerZip,
      notifyEmails,
      paymentMethodId,
      paymentMethodName,
      paymentMethodType,
      processorPaymentId,
      processorAccessToken,
      status,
      customerName,
      selectedInvoices,
      paymentAmount,
      paymentSurcharge,
      paymentCredits,
      paymentDate,
      expiry,
      ipAddress,
      userAgent,
      addl
    );
  };

  recordNewCardPayment = (
    payerId,
    payerName,
    payerEmail,
    payerZip,
    notifyEmails,
    cardMethod,
    cardMethodExpiry,
    processorPaymentId,
    processorAccessToken,
    status,
    customerName,
    selectedInvoices,
    paymentAmount,
    paymentSurcharge,
    paymentCredits,
    paymentDate
  ) => {
    const paymentMethodId = `cp_${cardMethod.processor_access_token}`;
    const paymentMethodName = `${cardMethod.brand.toUpperCase()} - ${
      cardMethod.last4
    }`;
    const paymentMethodType = PAYMENT_METHOD_TYPES.CARD;
    const expiry = cardMethodExpiry;
    const ipAddress = null;
    const userAgent = null;

    this.recordNewPayment(
      payerId,
      payerName,
      payerEmail,
      payerZip,
      notifyEmails,
      paymentMethodId,
      paymentMethodName,
      paymentMethodType,
      processorPaymentId,
      processorAccessToken,
      status,
      customerName,
      selectedInvoices,
      paymentAmount,
      paymentSurcharge,
      paymentCredits,
      paymentDate,
      expiry,
      ipAddress,
      userAgent
    );
  };

  recordNewCreditsPayment = (
    payerId,
    payerName,
    payerEmail,
    notifyEmails,
    processorPaymentId,
    processorAccessToken,
    status,
    customerName,
    selectedInvoices,
    paymentAmount,
    paymentSurcharge,
    paymentCredits,
    paymentDate
  ) => {
    const paymentMethodId = null;
    const paymentMethodName = "ACCOUNT CREDITS";
    const paymentMethodType = PAYMENT_METHOD_TYPES.CREDITS;
    const expiry = null;
    const ipAddress = null;
    const userAgent = null;
    const payerZip = null;

    this.recordNewPayment(
      payerId,
      payerName,
      payerEmail,
      payerZip,
      notifyEmails,
      paymentMethodId,
      paymentMethodName,
      paymentMethodType,
      processorPaymentId,
      processorAccessToken,
      status,
      customerName,
      selectedInvoices,
      paymentAmount,
      paymentSurcharge,
      paymentCredits,
      paymentDate,
      expiry,
      ipAddress,
      userAgent
    );
  };

  recordNewBankLoanPayment = (
    payerId,
    payerName,
    payerEmail,
    notifyEmails,
    bankMethod,
    paymentMethodId,
    processorPaymentId,
    processorAccessToken,
    status,
    customerName,
    paidLoan,
    principalAmount,
    interestAmount,
    paymentDate,
    ipAddress,
    userAgent,
    numInSeries,
    seriesLength,
    selectedInvoices
  ) => {
    const paidInvoices = selectedInvoices.map((i, index) => ({
      id: i.id,
      path: i.path,
      amount: Math.round(i.paymentAmount),
      account_id: i.account_id,
      index: index + 1,
      prefix: invoiceDisplayType(i.type.toUpperCase()),
    }));
    const paymentMethodName = formatBankName(bankMethod);
    const data = {
      payer_id: payerId,
      payer_name: payerName,
      payer_email: payerEmail,
      notify_emails: notifyEmails,
      method_id: paymentMethodId,
      method_name: paymentMethodName,
      method_type: "bank",
      processor_payment_id: processorPaymentId,
      processor_access_token: processorAccessToken,
      status: "created",
      created: this.firestore.FieldValue.serverTimestamp(),
      scheduled:
        status === TRANSACTION_STATUS.SCHEDULED
          ? this.firestore.Timestamp.fromDate(paymentDate)
          : null,
      completed:
        status === TRANSACTION_STATUS.SUCCESS ||
        status === TRANSACTION_STATUS.FAILURE ||
        status === TRANSACTION_STATUS.CLOSED
          ? this.firestore.FieldValue.serverTimestamp()
          : null,
      processed:
        status === TRANSACTION_STATUS.SCHEDULED
          ? this.firestore.Timestamp.fromDate(paymentDate)
          : this.firestore.FieldValue.serverTimestamp(),
      paid_loan: paidLoan,
      principal_amount: Math.round(principalAmount),
      interest_amount: Math.round(interestAmount),
      error: null,
      cqk_customer: customerName,
      ip_address: ipAddress,
      user_agent: userAgent,
      num_in_series: numInSeries,
      series_length: seriesLength,
      paid_invoices: paidInvoices,
    };

    const path = `customers/${customerName}/accounts/${payerId}/loans/${paidLoan.id}/loan_payments`;
    return this.firestoreAdd(path, data);
  };

  archivePaymentMethod = (paymentMethod) => {
    const path = paymentMethod.path;
    const data = {
      archived: true,
    };
    return this.firestoreUpdate(path, data);
  };

  createPaymentManually = (
    customerName,
    paymentAmount,
    paidInvoices,
    methodType
  ) => {
    const payerId = paidInvoices[0].account_id;
    const path = `customers/${customerName}/accounts/${payerId}/payments`;
    const data = {
      payer_id: payerId,
      method_id: null,
      method_name: null,
      method_type: methodType.toLowerCase(),
      status: "succeeded",
      cqk_customer: customerName,
      paid_invoices: paidInvoices.map((inv) => ({
        ...inv,
        amount: Math.round(inv.amount),
      })),
      amount: Math.round(paymentAmount),
      surcharge: 0,
      credits_used: 0,
      error: null,
      created: this.firestore.FieldValue.serverTimestamp(),
      completed: this.firestore.FieldValue.serverTimestamp(),
      processed: this.firestore.FieldValue.serverTimestamp(),
    };

    return this.firestoreAdd(path, data);
  };

  applyPaymentToInvoices = (
    customerName,
    payment,
    paidInvoices,
    completedDate
  ) => {
    //console.log(customerName, payment, paidInvoices, completedDate);
    const path = `customers/${customerName}/accounts/${payment.payer_id}/payments/${payment.id}p${payment.payer_id}`;
    const data = {
      id: payment.id,
      payer_id: payment.payer_id,
      amount: Math.round(payment.amount),
      surcharge: Math.round(payment.surcharge),
      credits_used: Math.round(payment.credits_used),
      method_id: payment.method_id,
      method_type: payment.method_type,
      method_path: payment.method_path,
      status: "succeeded",
      created: payment.created,
      processed: payment.processed || payment.scheduled || payment.created,
      completed: completedDate || this.firestore.FieldValue.serverTimestamp(),
      error: payment.error,
      cqk_customer: payment.cqk_customer,
      check_num: payment.check_num,
      company: payment.payer_id.charAt(0) === "9" ? "2" : "1",
      division: payment.division,
      department: payment.department,
      paid_invoices: paidInvoices,
      updated: this.firestore.FieldValue.serverTimestamp(),
    };

    return this.firestoreSet(path, data);
  };

  archiveOrphanPayment = (resolvePath, payment) => {
    const path = payment.path;
    const data = {
      ...payment,
      resolve_path: resolvePath,
      archived: true,
    };
    return this.firestoreSet(path, data);
  };

  deleteScheduledPayment = (paymentsPath) => {
    return this.firestoreDelete(paymentsPath);
  };

  deleteResolvedExceptionPayment = (paymentsPath) => {
    return this.firestoreDelete(paymentsPath);
  };

  updatePaymentMethodLastUsed = (accountPath, methodId) => {
    const path = `${accountPath}/payment_methods/${methodId}`;
    const data = {
      last_used: this.firestore.FieldValue.serverTimestamp(),
    };
    return this.firestoreUpdate(path, data);
  };

  updateCancelledPaymentStatus = (paymentPath) => {
    return this.firestoreUpdate(paymentPath, {
      status: TRANSACTION_STATUS.CANCELLED,
      updated: this.firestore.FieldValue.serverTimestamp(),
    });
  };

  updateCancelledLoanPaymentStatus = (paymentPath) => {
    return this.firestoreUpdate(paymentPath, {
      status: TRANSACTION_STATUS.CANCELLED.toLowerCase(),
      updated: this.firestore.FieldValue.serverTimestamp(),
    });
  };

  recordReminderMessageSent = (
    customerName,
    userId,
    userEmail,
    messageType,
    toEmails,
    ccEmails,
    message,
    accountId,
    sgMessageId,
    attachments
  ) => {
    if (this.emulateUser()) return;
    this.db
      .collection("customers")
      .doc(customerName)
      .collection("users")
      .doc(userId)
      .collection("messages")
      .add({
        type: messageType,
        sent: this.firestore.FieldValue.serverTimestamp(),
        from_email: userEmail,
        to_emails: toEmails,
        cc_emails: ccEmails,
        message: message,
        cqk_account_id: accountId,
        cqk_customer: customerName,
        sendgrid_message_id: sgMessageId,
        attachments: attachments,
      });
  };

  recordMessageSent = (
    account,
    messageType,
    from,
    toEmails,
    ccEmails,
    message,
    districtManager
  ) => {
    if (this.emulateUser()) return;

    let batch = this.db.batch();
    let messageRef = this.db
      .collection("users")
      .doc(from)
      .collection("messages")
      .doc();

    batch.set(messageRef, {
      fromEmail: from,
      distributorName: partnerOrgFromEmail(from),
      sentTo: account.Id,
      toEmails: toEmails,
      ccEmails: ccEmails,
      type: messageType,
      sentAt: this.createTimestamp(new Date()), // TODO: change to server timestamp
      message: message,
      districtManager: districtManager || "",
    });

    let qbInvoiceRef = this.db.doc(account.DocRef);
    let justTheEmails = toEmails.map((item) => item.email);
    batch.update(qbInvoiceRef, {
      ContactEmails: this.firestore.FieldValue.arrayUnion(...justTheEmails),
    });

    batch.commit();
  };

  /**
   * Other stuff
   *
   * */

  getSelectedRepInfo = (repEmail, callback) => {
    return this.db
      .collection("users")
      .doc(repEmail)
      .onSnapshot((doc) => {
        callback(doc.data());
      });
  };

  updateDistrictManagerForSalesRep = (salesRepEmail, newDistrictManager) => {
    return new Promise(async (resolve, reject) => {
      try {
        let batch = this.db.batch();
        let salesrepRef = this.db.collection("users").doc(salesRepEmail);

        console.log(salesRepEmail, newDistrictManager);
        batch.set(
          salesrepRef,
          { districtManager: newDistrictManager },
          { merge: true }
        );

        let accounts = await this.db
          .collection("users")
          .doc(salesRepEmail)
          .collection("qb_invoices")
          .get();

        accounts.forEach((doc) => {
          batch.set(
            doc.ref,
            { districtManager: newDistrictManager },
            { merge: true }
          );
        });

        let messages = await this.db
          .collection("users")
          .doc(salesRepEmail)
          .collection("messages")
          .get();
        messages.forEach((doc) => {
          batch.set(
            doc.ref,
            { districtManager: newDistrictManager },
            { merge: true }
          );
        });

        await batch.commit();

        resolve();
      } catch (err) {
        reject(err);
      }
    });
  };

  attachEmailToUser = (email, customerName, userid) => {
    const path = `customers/${customerName}/users/${userid}`;
    const data = { email: email };
    return this.firestoreUpdate(path, data);
  };

  getAllRepsForOrg = (org) => {
    let reps = [];

    return new Promise((resolve, reject) => {
      this.db
        .collection("users")
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            if (doc.id.includes(org)) reps.push(doc.id);
          });
          resolve(reps);
        });
    });
  };

  getDistrictRepsForManager = (districtManagerEmail) => {
    let reps = [];

    return new Promise((resolve, reject) => {
      this.db
        .collection("users")
        .where("districtManager", "==", districtManagerEmail)
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            reps.push(doc.id);
          });
          resolve(reps);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };

  getSalesRepsByDistrictId = (districtId, customerName, callback) => {
    return this.db
      .collection("customers")
      .doc(customerName)
      .collection("users")
      .where("district_id", "==", districtId)
      .where("role", "==", "sales")
      .get()
      .then((docs) => {
        let r;
        let reps = [];
        if (docs.size === 0) callback([]);
        else {
          docs.forEach((doc) => {
            r = doc.data();
            if (!reps.some((rep) => r.name === rep.name)) {
              r["path"] = doc.ref.path;
              reps.push(r);
            }
          });
          callback(reps);
        }
      });
  };

  getSalesRepsByCustomer = (customer, callback) => {
    return this.db
      .collection("customers")
      .doc(customer)
      .collection("users")
      .where("role", "==", "sales")
      .get()
      .then((docs) => {
        let r;
        let reps = [];
        if (docs.size === 0) callback([]);
        else {
          docs.forEach((doc) => {
            r = doc.data();
            r["path"] = doc.ref.path;
            reps.push(r);
          });
          callback(reps);
        }
      });
  };

  getSharedRepsForUser = (userEmail) => {
    let reps = [];

    return new Promise((resolve, reject) => {
      this.db
        .collection("users")
        .where("sharedWith", "array-contains", userEmail)
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            reps.push(doc.id);
          });
          resolve(reps);
        });
    });
  };

  removeShareLink = (userEmail, otherUserEmail) => {
    if (this.emulateUser()) return;

    console.log(userEmail, otherUserEmail);

    let batch = this.db.batch();

    let userRef = this.db.collection("users").doc(userEmail);
    batch.update(userRef, {
      sharedWith: firebase.firestore.FieldValue.arrayRemove(otherUserEmail),
    });

    let otherUserRef = this.db.collection("users").doc(otherUserEmail);
    batch.update(otherUserRef, {
      sharedWith: firebase.firestore.FieldValue.arrayRemove(userEmail),
    });

    console.log(otherUserRef);

    return new Promise((resolve, reject) => {
      batch
        .commit()
        .then(() => {
          console.log("link removal successful");
          resolve("success");
        })
        .catch((e) => {
          console.error("link removal unsuccessful");
          reject({ error: e });
        });
    });
  };

  getGoogleTokens = async (userEmail) => {
    let tokenQuery = await this.db
      .collection("users")
      .doc(userEmail)
      .collection("tkns")
      .doc("google")
      .get();

    let tokens = tokenQuery.data();
    console.log(tokens);

    return tokens;
  };

  logPageview = (pagePath, pageTitle) => {
    if (this.emulateUser()) return;
    this.analytics.logEvent("page_view", {
      page_path: pagePath,
      page_title: pageTitle,
      page_location: window.location.origin + pagePath,
    });
  };

  logPayment = (
    distributorName,
    customerId,
    paymentMethodType,
    amount,
    surcharge = 0
  ) => {
    if (this.emulateUser()) return;
    this.analytics.logEvent("purchase", {
      value: amount,
      affiliation: paymentMethodType,
      shipping: surcharge.toFixed(2),
      currency: "USD",
      transaction_id: `${distributorName}:${customerId}`,
    });
  };

  logAutopayChange = (autopayState) => {
    if (this.emulateUser()) return;
    if (!this.analytics) return;
    this.analytics.logEvent("set_checkout_option", {
      checkout_option: "AUTOPAY",
      value: autopayState,
    });
  };

  logMsgSent = () => {
    if (this.emulateUser()) return;
    this.analytics.logEvent("goal_completion", { type: "message_sent" });
  };

  logSignInEvent = (eventType) => {
    this.analytics.logEvent(eventType, { method: "email" });
    this.logUserOrg();
  };

  logSignIn = () => {
    this.analytics.logEvent("login", { method: "email" });
    this.logUserOrg();
  };

  logSignUp = () => {
    this.analytics.logEvent("sign_up", { method: "email" });
    this.logUserOrg();
  };

  logUserOrg = () => {
    let email = this.auth.currentUser.email.slice();
    let org = email.substring(email.indexOf("@") + 1, email.indexOf("."));
    this.analytics.setUserProperties({ org: org });
  };

  trackEvent = (
    trackType,
    eventType,
    customerName,
    accountNumber,
    addlData = {}
  ) => {
    if (this.emulateUser()) return;
    const email = this.getUserEmail();
    const func = this.functions.httpsCallable("track-trackEvent");
    const environment = isLocal() ? "test" : "prod";
    return func({
      trackType: trackType,
      eventType: eventType,
      userEmail: email,
      customerName: customerName,
      accountNumber: accountNumber,
      environment: environment,
      addlData: addlData,
    });
  };
}

export default Firebase;
