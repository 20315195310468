import React, { useState, useEffect, useContext, useCallback } from "react";
import {
  CRow,
  CCol,
  CButton,
  CLabel,
  CCollapse,
  CModal,
  CModalHeader,
  CModalBody,
  CModalFooter,
  CFormGroup,
  CInputRadio,
} from "@coreui/react";

import {
  SubmitBtn,
  ConfirmationDialog,
  PayButton,
  InlineLoading,
  InlineNotification,
  RadioSelectionGroup,
  MethodSelection,
} from "./components";
import {
  PAYMENT_METHOD_TYPES,
  TRANSACTION_STATUS,
  INSTALLMENT_OFFERS,
  isImmediatePayment,
  isFuturePayment,
  formatAsMoney,
  getCardSurchargeRate,
  convertToCents,
  cardIcons,
  getCardPointeiFrameSourceUrl,
  fetchIpAddress,
  getUserAgent,
  plusnWeeks,
  isPlaidLinkedBank,
  formatBankName,
} from "./helpers";
import Strings from "./strings";
import { FirebaseContext } from "../firebase";
import { usePlaidLink } from "react-plaid-link";

import { freeSet } from "@coreui/icons";
import CIcon from "@coreui/icons-react";
import { toast } from "react-toastify";
import { Info } from "luxon";

export const PlaidLink = ({
  token,
  account,
  contactEmail,
  bankMethod = null,
  callback,
}) => {
  const firebase = useContext(FirebaseContext);

  const onExit = useCallback(async (err, metadata) => {
    //console.log(err);
    callback();
  }, []);

  // Vanilla Link:
  // exchange public token and get routing & account numbers

  // Microdeposit Verification:
  // exchange public token and get routing & account numbers

  // Microdeposit Initiation
  // exchange public token

  // Link Update:
  // update status to succeeded

  const onSuccess = useCallback(async (publicToken, metadata) => {
    try {
      let token, bankItemId, accountNumber, routingNumber;

      // For Link Update, where we already have a access token,
      // just use the information from the bankMethod
      if (bankMethod && bankMethod.status === "requires_link_update") {
        token = bankMethod.processor_access_token;
        bankItemId = bankMethod.id.split("_")[1];
        routingNumber = bankMethod.routing_number;
      }

      // For all others,
      // exchange public token
      else {
        const { data } = await firebase.getBankAccessToken(publicToken);
        const { accessToken, itemId } = data;

        token = accessToken;
        bankItemId = itemId;

        // for Vanilla Link and Microdeposit Verification,
        // get the account number and routing number
        if (
          metadata.accounts[0].verification_status === null ||
          metadata.accounts[0].verification_status === "manually_verified"
        ) {
          const response = await firebase.getBankAuthData(accessToken);
          accountNumber = response.data.numbers.ach[0].account;
          routingNumber = response.data.numbers.ach[0].routing;
        }
      }

      const bankData = {
        ...metadata,
        accountNumber: "",
        routingNumber: routingNumber,
      };

      //console.log(bankData);
      await firebase.recordNewBankPaymentMethod(
        account,
        bankData,
        token,
        bankItemId,
        contactEmail
      );
    } catch (err) {
      //console.error(err);
    } finally {
      callback();
    }
  }, []);

  const { open, ready } = usePlaidLink({ token, onSuccess, onExit });

  useEffect(() => {
    if (ready) {
      open();
    }
  }, [ready, open]);

  return <></>;
};

export const LinkWithPlaid = ({ token, customerName, entityId, callback }) => {
  const firebase = useContext(FirebaseContext);

  const onSuccess = useCallback(async (publicToken, metadata) => {
    try {
      const { data } = await firebase.getBankAccessToken(publicToken);
      const { accessToken, itemId } = data;
      const response = await firebase.getBankAuthData(accessToken);
      const routingNumber = response.data.numbers.ach[0].routing;

      const bankData = {
        ...metadata,
        itemId: itemId,
        processor_access_token: accessToken,
        routingNumber: routingNumber,
      };

      callback(bankData);
    } catch (err) {
      callback(null);
    }
  }, []);

  const onExit = useCallback(async (err, metadata) => {
    callback(null);
  }, []);

  const { open, ready } = usePlaidLink({ token, onSuccess, onExit });

  useEffect(() => {
    if (ready) {
      open();
    }
  }, [ready, open]);

  return <></>;
};

export const LinkWithPlaidWrapper = ({
  customerName,
  entityId,
  token,
  callback,
}) => {
  const firebase = useContext(FirebaseContext);
  const [bankLinkToken, setBankLinkToken] = useState(null);

  const createLinkToken = () => {
    firebase
      .createBankLinkToken(customerName, entityId)
      .then(({ data }) => {
        setBankLinkToken(data);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const createLinkVerifyToken = async () => {
    firebase
      .createBankLinkVerifyToken(customerName, entityId, token)
      .then(({ data }) => {
        setBankLinkToken(data);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  useEffect(() => {
    if (token) createLinkVerifyToken();
    else createLinkToken();
  }, []);

  if (!bankLinkToken) return null;

  return (
    <LinkWithPlaid
      token={bankLinkToken}
      customerName={""}
      entityId={entityId.id}
      callback={callback}
    />
  );
};

export const execCardPayment = async (
  firebase,
  account,
  payerEmail,
  notifyEmails,
  card,
  paymentDate,
  selectedInvoices,
  paymentAmount,
  paymentSurcharge,
  paymentCredits
) => {
  const payerId = account.id;
  const payerName = account.name;
  const customerName = account.customer;
  const payerZip = account.billing_zip
    ? account.billing_zip.trim().slice(0, 5)
    : null;

  const token = card.processor_access_token;
  const expiry = `${card.exp_year}${card.exp_month}`;
  const invoiceIds = selectedInvoices.map((inv) => inv.id);

  const status = TRANSACTION_STATUS.CREATED;

  let transactionId;
  if (isImmediatePayment(paymentDate)) {
    // executes payment on cardpointe
    const { data } = await firebase.execCardPointePayment(
      token,
      expiry,
      paymentAmount,
      paymentSurcharge,
      paymentCredits,
      payerName,
      payerZip,
      invoiceIds
    );
    transactionId = data;
    //status = TRANSACTION_STATUS.CREATED;
  } else if (isFuturePayment(paymentDate)) {
    transactionId = null;
    //status = TRANSACTION_STATUS.SCHEDULED;
  } else {
    throw new Error(Strings.ERROR_PAST_PAYMENT_DATE);
  }

  // record new payment
  //console.log("tranasctionid is", transactionId);
  await firebase.recordNewCardPayment(
    payerId,
    payerName,
    payerEmail,
    payerZip,
    notifyEmails,
    card,
    expiry,
    transactionId,
    token,
    status,
    customerName,
    selectedInvoices,
    paymentAmount,
    paymentSurcharge,
    paymentCredits,
    paymentDate
  );

  return status;
};

export const execBankPayment = async (
  firebase,
  account,
  payerEmail,
  notifyEmails,
  bank,
  paymentDate,
  selectedInvoices,
  paymentAmount,
  paymentSurcharge,
  paymentCredits,
  additionalPaymentInfo = {},
  isAnansiiPayer = false
) => {
  // sets up info for recordnewpayment
  const payerId = account.id;
  const paymentMethodId = bank.account_id;
  const payerName = account.name;
  const customerName = account.customer;

  const token = bank.processor_access_token;
  let ipAddress;
  try {
    ipAddress = await fetchIpAddress();
  } catch (err) {
    ipAddress = "12.10.41.2";
  } finally {
    const userAgent = getUserAgent();

    const status = TRANSACTION_STATUS.CREATED;
    // generates a new payment
    let transactionId;
    if (isImmediatePayment(paymentDate)) {
      // executes payment on plaid
      const { data } = await firebase.execPlaidTransferPayment(
        token,
        paymentAmount,
        paymentSurcharge,
        paymentCredits,
        paymentMethodId,
        isAnansiiPayer ? "ANANSII INC" : payerName,
        customerName,
        ipAddress,
        userAgent
      );
      transactionId = data.id;
      //console.log(transactionId);
      //status = TRANSACTION_STATUS.CREATED;
    } else if (isFuturePayment(paymentDate)) {
      transactionId = null;
      //status = TRANSACTION_STATUS.SCHEDULED;
    } else {
      throw new Error(Strings.ERROR_PAST_PAYMENT_DATE);
    }

    //console.log("tranasctionid is", transactionId);
    await firebase.recordNewBankPayment(
      payerId,
      payerName,
      payerEmail,
      notifyEmails,
      bank,
      paymentMethodId,
      transactionId,
      token,
      status,
      customerName,
      selectedInvoices,
      paymentAmount,
      paymentSurcharge,
      paymentCredits,
      paymentDate,
      ipAddress,
      userAgent,
      additionalPaymentInfo
    );

    return status;
  }
};

export const createLoanRepaymentSeries = async (
  firebase,
  account,
  payerEmail,
  notifyEmails,
  paymentMethod,
  paymentSeries,
  seriesLength,
  loanId,
  loanAmount,
  firstPaymentAmount,
  selectedInvoices
) => {
  const payerId = account.id;
  const paymentMethodId = paymentMethod.account_id;
  const payerName = account.name;
  const customerName = account.customer;

  const token = paymentMethod.processor_access_token;
  let ipAddress;
  try {
    ipAddress = await fetchIpAddress();
  } catch (err) {
    ipAddress = "12.10.41.2";
  } finally {
    const userAgent = getUserAgent();

    const paidLoan = {
      id: loanId,
      amount: Math.round(loanAmount),
      path: `customers/${customerName}/accounts/${payerId}/loans/${loanId}`,
    };

    let numInSeries, status, transactionId, doc;
    const paymentSurcharge = 0;
    const paymentCredits = 0;
    const loanPaymentDocRefs = [];
    const loanPaymentCustomerName = "anansii";

    for (const [i, payment] of paymentSeries.entries()) {
      numInSeries = i + 1;
      // execute the first payment in the series immediately
      if (numInSeries === 1) {
        const { data } = await firebase.execPlaidTransferPayment(
          token,
          payment.principal_amount + payment.interest_amount,
          paymentSurcharge,
          paymentCredits,
          paymentMethodId,
          payerName,
          loanPaymentCustomerName,
          ipAddress,
          userAgent
        );
        transactionId = data.id;
        //console.log("payment in series", transactionId);
        status = TRANSACTION_STATUS.PENDING;
      } else {
        transactionId = null;
        status = TRANSACTION_STATUS.SCHEDULED;
      }

      doc = await firebase.recordNewBankLoanPayment(
        payerId,
        payerName,
        payerEmail,
        notifyEmails,
        paymentMethod,
        paymentMethodId,
        transactionId,
        token,
        status,
        customerName,
        paidLoan,
        payment.principal_amount,
        payment.interest_amount,
        payment.date,
        ipAddress,
        userAgent,
        numInSeries,
        seriesLength,
        selectedInvoices
      );

      loanPaymentDocRefs.push(doc.id);
    }

    return loanPaymentDocRefs[0];
  }
};

export const execCreditsPayment = async (
  firebase,
  account,
  payerEmail,
  notifyEmails,
  paymentDate,
  selectedInvoices,
  paymentAmount,
  paymentSurcharge,
  paymentCredits
) => {
  const payerId = account.id;
  const payerName = account.name;
  const customerName = account.customer;
  const transactionId = null;
  const token = null;

  // generates a new payment
  let status;
  if (isImmediatePayment(paymentDate)) {
    status = TRANSACTION_STATUS.CREATED;
  } else if (isFuturePayment(paymentDate)) {
    status = TRANSACTION_STATUS.CREATED;
  } else {
    toast.error(Strings.ERROR_PAST_PAYMENT_DATE);
    return;
  }

  //console.log("tranasctionid is", transactionId);
  await firebase.recordNewCreditsPayment(
    payerId,
    payerName,
    payerEmail,
    notifyEmails,
    transactionId,
    token,
    status,
    customerName,
    selectedInvoices,
    paymentAmount,
    paymentSurcharge,
    paymentCredits,
    paymentDate
  );

  return status;
};

export const CardPointeCardFrame = ({
  account,
  handleNewCardExit,
  methodSelectionCta = "Change Payment Method",
}) => {
  const firebase = useContext(FirebaseContext);

  const serialize = (obj) => {
    let str = [];
    for (let p in obj)
      if (obj.hasOwnProperty(p)) {
        str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
      }
    return str.join("&");
  };

  const identifyBrand = (digit) => {
    switch (digit) {
      case "3":
        return "amex";
      case "4":
        return "visa";
      case "5":
        return "mastercard";
      case "6":
        return "discover";
      default:
        return null;
    }
  };

  useEffect(() => {
    firebase.trackEvent(
      "event",
      "view_addcard_page",
      account.customer,
      account.id,
      {}
    );
  }, []);

  const [cardToken, setCardToken] = useState(null);
  useEffect(() => {
    setCardToken("");
  }, []);

  const [cardBrand, setCardBrand] = useState(null);
  const [lastFour, setLastFour] = useState(null);
  const [expiryMonth, setExpiryMonth] = useState(null);
  const [expiryYear, setExpiryYear] = useState(null);
  const [sourceUrl, setSourceUrl] = useState(null);
  useEffect(() => {
    const url = getCardPointeiFrameSourceUrl(window.location.host);
    setSourceUrl(url);
  }, []);

  const [params, setParams] = useState(null);
  useEffect(() => {
    const p = {
      tokenizewheninactive: true,
      useexpiry: true,
      useexpiryfield: true,
      usecvv: true,
      cardinputmaxlength: 19,
      cardnumbernumericonly: true,
      enhancedresponse: true,
      invalidcreditcardevent: true,
      invalidcvvevent: true,
      invalidexpiryevent: true,
      orientation: "horizontal",
      placeholder: "Card Number",
      placeholdermonth: "MM",
      placeholderyear: "YYYY",
      formatinput: true,
      cardlabel: " ",
      expirylabel: " ",
      cvvlabel: " ",
      placeholdercvv: "CVC",
      maskfirsttwo: true,
    };

    const styles = encodeURI(
      "css=.error{color:red;border-color:red;}input{height:18px;font-size:16px;border-radius:0.25rem;padding:.375rem .75rem;border:1px solid grey;"
    );
    setParams(`${serialize(p)}&${styles}`);
  }, []);

  const handleTokenEvent = (event) => {
    window.addEventListener(
      "message",
      (event) => {
        const data = JSON.parse(event.data);
        //console.log(data);
        if (data.token === "" || !data.expiry) {
          return;
        }

        const cardToken = data.token;
        const brand = identifyBrand(cardToken.slice(1, 2));
        if (!brand) {
          toast.error(
            "Card type not recognized. Please check your account number and try again."
          );
          return;
        }

        const last4 = cardToken.slice(-4);
        const expYear = data.expiry.slice(0, 4);
        const expMonth = data.expiry.slice(4);

        setCardToken(cardToken);
        setExpiryMonth(expMonth);
        setExpiryYear(expYear);
        setCardBrand(brand);
        setLastFour(last4);
      },
      false
    );
  };

  const saveNewCard = async () => {
    //console.log("saving new card");
    //console.log(cardToken, cardBrand, lastFour, expiryMonth, expiryYear);
    await firebase.recordNewCardPaymentMethod(
      account,
      cardToken,
      cardBrand,
      expiryMonth,
      expiryYear,
      lastFour
    );
    handleNewCardExit();
  };

  if (!sourceUrl || !params) return null;

  return (
    <>
      <CRow className="mt-n3 align-items-center">
        <CCol className="px-2">
          <form name="cardpointe-card-form" id="cardpointe-card-form">
            <iframe
              id="cardpointe-card-form-frame"
              title="cardpointe-card-form-frame"
              name="cardpointe-card-form-frame"
              src={`${sourceUrl}?${params}`}
              frameBorder="0"
              scrolling="no"
              width="400"
              onLoad={handleTokenEvent}
            ></iframe>
            <input type="hidden" name="card-token" id="card-token" />
          </form>
        </CCol>
        <CCol xs="auto">
          <CButton
            color="primary"
            variant="outline"
            shape="pill"
            onClick={() => handleNewCardExit()}
            className="align-self-center font-weight-bold text-nowrap"
          >
            {methodSelectionCta}
          </CButton>
        </CCol>
      </CRow>
      <CRow className="mt-n3 mb-3 text-center">
        <CCol>
          <InlineNotification
            message={`${getCardSurchargeRate(account.customer) * 100}${
              Strings.PAYMENT_CARD_SURCHARGE_WARNING_SUFFIX
            }`}
            type="muted"
          />
        </CCol>
      </CRow>
      <CRow>
        <CCol>
          <SubmitBtn
            cta="Save"
            callback={saveNewCard}
            isLoading={false}
            isBlockBtn={true}
            disabledWhen={
              !cardToken ||
              !cardBrand ||
              !lastFour ||
              !expiryMonth ||
              !expiryYear
            }
          />
        </CCol>
      </CRow>
    </>
  );
};

export const InstallmentsPaymentMethod = ({
  account,
  contactEmail,
  methods,
  selectedInvoices,
  paySubtotal,
  creditsApplied,
  handleMethodChange,
  handlePaymentCreated,
}) => {
  const firebase = useContext(FirebaseContext);
  const [selection, setSelection] = useState(null);

  useEffect(() => {
    firebase.trackEvent(
      "event",
      "view_installments_page",
      account.customer,
      account.id,
      {}
    );
  }, []);
  useEffect(() => {
    setSelection(INSTALLMENT_OFFERS[0].payments.toString());
  }, []);

  const [selectedOffer, setSelectedOffer] = useState(null);
  useEffect(() => {
    if (!selection) return;
    const o = INSTALLMENT_OFFERS.find(
      (offer) => selection === offer.payments.toString()
    );
    if (!o) return;
    setSelectedOffer(o);
  }, [selection]);

  const [options, setOptions] = useState(null);
  useEffect(() => {
    const paymentOptions = INSTALLMENT_OFFERS.map((offer) => ({
      text: `${offer.payments} weekly payments (${offer.feePct * 100}% fee)`,
      value: offer.payments.toString(),
    }));
    setOptions(paymentOptions);
  }, []);

  const [numPayments, setNumPayments] = useState(0);
  useEffect(() => {
    if (!selectedOffer) return;
    setNumPayments(selectedOffer.payments);
  }, [selectedOffer]);

  const [bankMethods, setBankMethods] = useState(null);
  useEffect(() => {
    if (!methods) return;
    setBankMethods(methods.filter((method) => method.type === "bank"));
  }, [methods]);

  const [selectedMethod, setSelectedMethod] = useState(null);
  useEffect(() => {
    if (!bankMethods || bankMethods.length === 0) return;
    setSelectedMethod(bankMethods[0]);
  }, [bankMethods]);

  const [fee, setFee] = useState(0);
  useEffect(() => {
    if (paySubtotal === null || !selectedOffer) return;
    const f = selectedOffer.feePct * (paySubtotal - creditsApplied);
    setFee(f);
  }, [paySubtotal, selectedOffer]);

  const [totalPayment, setTotalPayment] = useState(0);
  useEffect(() => {
    const total = paySubtotal - creditsApplied + fee;
    setTotalPayment(total);
  }, [fee, paySubtotal, creditsApplied]);

  const [paymentSeries, setPaymentSeries] = useState(null);
  useEffect(() => {
    if (!numPayments) return;

    let remainingPrincipal = paySubtotal - creditsApplied;
    let remainingFee = fee;
    let remainingPayments = numPayments;

    //console.log(paySubtotal-creditsApplied, fee);

    const series = [];
    let principalPayment, interestPayment, payment;
    for (let i = 0; i < numPayments; i++) {
      //console.log(remainingPrincipal, remainingFee, remainingPayments);
      principalPayment = Math.round(remainingPrincipal / remainingPayments);
      interestPayment = Math.round(remainingFee / remainingPayments);

      payment = {
        principal_amount: principalPayment,
        interest_amount: interestPayment,
        date: plusnWeeks(new Date(), i),
      };
      series.push(payment);

      remainingPrincipal = remainingPrincipal - principalPayment;
      remainingFee = remainingFee - interestPayment;
      remainingPayments--;
    }
    //console.log(series);
    setPaymentSeries(series);
  }, [paySubtotal, creditsApplied, fee, numPayments]);

  const [firstPaymentAmount, setFirstPaymentAmount] = useState(0);
  useEffect(() => {
    if (!paymentSeries) return;
    const amount =
      paymentSeries[0].principal_amount + paymentSeries[0].interest_amount;
    setFirstPaymentAmount(amount);
  }, [paymentSeries]);

  const [showPaymentMethodsPanel, setShowPaymentMethodsPanel] = useState(false);
  useEffect(() => {
    if (!methods) return;

    if (!selectedMethod) setShowPaymentMethodsPanel(true);
    else setShowPaymentMethodsPanel(false);
  }, [selectedMethod, methods]);
  const handleAutoPaymentMethodChange = (selected) => {
    setSelectedMethod(selected);
    setShowPaymentMethodsPanel(false);
  };

  const [isProcessingInstallment, setIsProcessingInstallment] = useState(false);
  const handleInstallmentsPlanConfirmed = async () => {
    setIsProcessingInstallment(true);

    const paymentMethod = selectedMethod;
    if (!paymentMethod || paymentMethod.type !== "bank") {
      toast.error("Please add a bank account below to continue");
      setIsProcessingInstallment(false);
      return;
    }

    const paymentDate = new Date();
    const surcharge = 0;
    const notifyEmails = [contactEmail];

    const completed = {
      loan_payment_doc_id: null,
      loan_id: null,
    };

    firebase.trackEvent(
      "event",
      "pay_installment_init",
      account.customer,
      account.id,
      {
        customerName: account.customer,
        amount: paySubtotal,
        credits_used: creditsApplied,
        surcharge: surcharge,
        paymentMethod: selectedMethod,
        seriesLength: numPayments,
        selectedInvoices: selectedInvoices,
      }
    );

    try {
      const loanCollectionPath = `customers/${account.customer}/accounts/${account.id}/loans`;
      const loanDocRef = firebase.firestoreDoc(loanCollectionPath);
      const loanId = loanDocRef.id;
      const loanDocPath = `${loanCollectionPath}/${loanId}`;

      // create loan record in firestore
      await firebase.recordNewInstallmentLoan(
        account.customer,
        account.id,
        loanId,
        numPayments,
        selectedOffer.feePct,
        paySubtotal - creditsApplied,
        fee,
        paymentSeries,
        selectedInvoices
      );

      completed["loan_id"] = loanId;

      // execute first weekly payment from customer to Anansii
      const seriesLength = numPayments;
      const payerEmail = contactEmail;

      const loanPaymentDocId = await createLoanRepaymentSeries(
        firebase,
        account,
        payerEmail,
        notifyEmails,
        paymentMethod,
        paymentSeries,
        seriesLength,
        loanId,
        totalPayment,
        firstPaymentAmount,
        selectedInvoices
      );

      completed["loan_payment_doc_id"] = loanPaymentDocId;

      firebase.trackEvent(
        "event",
        "pay_installment_series_created",
        account.customer,
        account.id,
        {
          customerName: account.customer,
          amount: paySubtotal,
          credits_used: creditsApplied,
          surcharge: surcharge,
          paymentMethod: selectedMethod,
          seriesLength: numPayments,
          selectedInvoices: selectedInvoices,
          loanId: loanId,
        }
      );

      // get anansii payment method for paying birite
      const anansiiPaymentMethod = await firebase.getAnansiiInstallmentsBankAccount();

      const additionalPaymentInfo = { related_loan_path: loanDocPath };
      // execute full payment from Anansii to supplier
      await execBankPayment(
        firebase,
        account,
        null, // payerEmail
        null, // notifyEmails: do not send mail for this transaction
        anansiiPaymentMethod,
        paymentDate,
        selectedInvoices,
        paySubtotal, // paymentAmount: do not include anansii fees in payment
        surcharge,
        creditsApplied,
        additionalPaymentInfo,
        true
      );

      completed["pay_invoices"] = true;

      firebase.trackEvent(
        "event",
        "pay_installment_completed",
        account.customer,
        account.id,
        {
          customerName: account.customer,
          amount: paySubtotal,
          credits_used: creditsApplied,
          surcharge: surcharge,
          paymentMethod: selectedMethod,
          seriesLength: numPayments,
          selectedInvoices: selectedInvoices,
          loanId: loanId,
        }
      );

      // trigger successful payment flow
      handlePaymentCreated(
        PAYMENT_METHOD_TYPES.INSTALLMENTS,
        firstPaymentAmount,
        0,
        creditsApplied,
        selectedInvoices,
        "succeeded",
        selectedMethod
      );

      handleMethodChange();
    } catch (err) {
      //console.error(err);
      toast.error(
        "There was an error processing your request. Please try again later"
      );

      if (completed.loan_payment_doc_id)
        firebase.updateCancelledLoanPaymentStatus(
          `customers/${account.customer}/accounts/${account.id}/loans/${completed.loan_id}/loan_payments/${completed.loan_payment_doc_id}`
        );
      if (completed.loan_id)
        firebase.cancelInstallmentLoan(
          account.customer,
          account.id,
          completed.loan_id
        );

      firebase.trackEvent(
        "exception",
        "pay_installment_failed",
        account.customer,
        account.id,
        {
          completed: completed,
        }
      );
    } finally {
      setIsProcessingInstallment(false);
    }
  };

  const [showMaxCreditBanner, setShowMaxCreditBanner] = useState(false);
  useEffect(() => {
    if (!account) {
      setShowMaxCreditBanner(false);
      return;
    }

    const isOverLimit = totalPayment > account.installments_credit_limit;
    if (isOverLimit) {
      firebase.trackEvent(
        "event",
        "pay_installment_unavailable_limit_exceeded",
        account.customer,
        account.id,
        {
          totalAmount: totalPayment,
          limit: account.installments_credit_limit,
        }
      );
    }

    setShowMaxCreditBanner(isOverLimit);
  }, [account, totalPayment]);

  if (!options) return <InlineLoading />;

  return (
    <>
      <CRow className="align-items-top">
        <CCol xs="auto" className="ml-n4">
          <CLabel className="font-weight-bold bg-light text-body rounded-lg pr-3 py-1 pl-3">
            Pay in installments
          </CLabel>
        </CCol>
        <CCol className="">
          <CButton
            color="link"
            className="float-right px-0 font-weight-bold"
            onClick={handleMethodChange}
            disabled={isProcessingInstallment}
          >
            Go back
          </CButton>
        </CCol>
      </CRow>

      <CRow className="border-bottom py-3">
        <CCol>
          <CRow>
            <CCol xs="4">
              <CLabel
                htmlFor="payment-method-selector"
                className="font-weight-bold text-nowrap"
              >
                1. Choose payment plan
              </CLabel>
            </CCol>
            <CCol className="ml-2">
              <RadioSelectionGroup
                label=""
                name="installment-plans"
                options={options}
                selection={selection}
                handleSelectionChange={setSelection}
                inline={false}
                textClasses="pl-3"
              />
            </CCol>
          </CRow>
        </CCol>
      </CRow>
      <CRow className="py-3 border-bottom">
        <CCol xs="4">
          <CLabel
            htmlFor="payment-method-selector"
            className="font-weight-bold"
          >
            2. Select a payment method
          </CLabel>
        </CCol>
        <CCol className={`${methods && methods.length > 0 ? "" : "mt-n3"}`}>
          {selectedMethod && (
            <CRow className="align-items-center">
              <CCol
                xs="auto"
                className="p-1 ml-3 bg-light border-light d-none d-sm-flex rounded-lg"
              >
                <CIcon
                  title="bank-icon"
                  content={freeSet.cilBank}
                  alt="bank-icon"
                  size={"lg"}
                />
              </CCol>
              <>
                <CCol>
                  <p className="font-weight-bold mb-0">
                    {formatBankName(selectedMethod)}
                  </p>
                  <small className="text-muted">
                    Payments will be automatically made from this account
                  </small>
                </CCol>
                <CCol xs="auto">
                  <CButton
                    color="link"
                    onClick={() =>
                      setShowPaymentMethodsPanel(!showPaymentMethodsPanel)
                    }
                    className="font-weight-bold"
                  >
                    Change
                  </CButton>
                </CCol>
              </>
            </CRow>
          )}
          {showPaymentMethodsPanel && (
            <CCollapse show={showPaymentMethodsPanel}>
              <CRow>
                <CCol>
                  <MethodSelection
                    paymentAccount={account}
                    contactEmail={contactEmail}
                    methods={methods}
                    usesPlaidLink={true}
                    handleSelection={handleAutoPaymentMethodChange}
                    showCardOptions={false}
                    showHeader={false}
                  />
                </CCol>
              </CRow>
            </CCollapse>
          )}
        </CCol>
      </CRow>
      <CRow className="py-3">
        <CCol xs="4">
          <CLabel
            htmlFor="payment-method-selector"
            className="font-weight-bold text-nowrap"
          >
            3. Review & Confirm
          </CLabel>
        </CCol>
        <CCol>
          <CRow className="align-items-center mb-1">
            <CCol
              xs="auto"
              className="p-1 ml-3 bg-light border-light d-none d-sm-flex rounded-lg"
            >
              <CIcon
                title="calendar-icon"
                content={freeSet.cilCalendar}
                alt="calendar-icon"
                size={"lg"}
              />
            </CCol>
            <CCol>
              <p className="m-0">
                <b>{numPayments} total payments</b> (
                {formatAsMoney(firstPaymentAmount / 100)} due today)
              </p>
            </CCol>
          </CRow>
          <CRow className="align-items-center">
            <CCol
              xs="auto"
              className="p-1 ml-3 bg-light border-light d-none d-sm-flex rounded-lg"
            >
              <CIcon
                title="money-icon"
                content={freeSet.cilMoney}
                alt="money-icon"
                size={"lg"}
              />
            </CCol>
            <CCol>
              <p className="m-0">
                <b>{formatAsMoney(totalPayment / 100)} total</b> (includes{" "}
                {formatAsMoney(fee / 100)} fee)
              </p>
            </CCol>
          </CRow>
        </CCol>
      </CRow>
      {/* Over Installments Limit Notification */}
      <CCollapse show={showMaxCreditBanner}>
        <CRow className="mb-2">
          <CCol>
            <CRow className="font-weight-bold bg-warning text-white mx-n2 rounded-lg shadow-sm align-items-center p-2 text-center">
              <CCol className="align-bottom">
                <span>
                  <CIcon
                    content={freeSet.cilWarning}
                    className="text-white pb-1"
                    size="lg"
                  />{" "}
                  Only qualified up to{" "}
                  {formatAsMoney(account.installments_credit_limit / 100)}
                </span>
              </CCol>
            </CRow>
          </CCol>
        </CRow>
      </CCollapse>
      {/**/}

      <CRow>
        <CCol>
          <PayButton
            cta="Confirm Plan & Pay"
            amount={firstPaymentAmount / 100}
            isLoading={isProcessingInstallment}
            handlePayment={handleInstallmentsPlanConfirmed}
            isDisabled={showMaxCreditBanner}
          />
        </CCol>
      </CRow>
    </>
  );
};

export const BankPaymentMethod = ({
  account,
  selectedInvoices,
  paySubtotal,
  creditsApplied,
  paymentDate,
  methods,
  selectedMethod,
  contactEmail,
  notifyEmails,
  isPaying,
  isBackOfficePayment,
  handlePayProgressChange,
  handleMethodChange,
  handlePaymentCreated,
  handleForceSelectedMethodUpdate,
}) => {
  const firebase = useContext(FirebaseContext);
  const [showConfirmation, setShowConfirmation] = useState(false);
  useEffect(() => {
    setShowConfirmation(false);
  }, []);
  /**
   * Bank Methods
   * ~~~~~~~~~~~~~~~~~
   * Payment methods attached to account that have the type "bank"
   *
   * */

  const [bankMethods, setBankMethods] = useState(null);
  useEffect(() => {
    if (!methods) return;
    setBankMethods(methods.filter((method) => method.type === "bank"));
  }, [methods]);

  /**
   * Bank Balance
   * ~~~~~~~~~~~~~~~~~
   * If balance is null or outdated,
   * refresh balance
   * */

  const hasRecentBalance = (bankMethod) => {
    let lastRefreshedTimestamp;
    if (bankMethod.balance)
      lastRefreshedTimestamp = bankMethod.balance.last_refreshed;
    else lastRefreshedTimestamp = null;

    if (!lastRefreshedTimestamp) return false;
    const ONE_HOUR_AGO = new Date() - 60 * 60 * 1000;

    return (
      lastRefreshedTimestamp.valueOf() >
      firebase.firestore.Timestamp.fromMillis(ONE_HOUR_AGO).valueOf()
    );
  };

  const refreshBalance = async (bankMethod) => {
    if (hasRecentBalance(bankMethod) || !isPlaidLinkedBank(bankMethod)) return;

    try {
      //console.log("refreshing balance");
      await firebase.getBankBalance(
        bankMethod.processor_access_token,
        bankMethod.account_id,
        bankMethod.path
      );
    } catch (err) {
      //console.error(err);
    }
  };

  useEffect(() => {
    if (!selectedMethod || !selectedMethod.balance) return;

    if (!hasRecentBalance(selectedMethod)) {
      //console.log("does not have recent balance");
      refreshBalance(selectedMethod);
    } else {
      //console.log("does have recent balance");
    }
  }, [selectedMethod]);

  /**
   * Do ACH payment
   * ~~~~~~~~~~~~~~~~~~~~~
   * */

  const submitBankPayment = async () => {
    handlePayProgressChange(true);
    const payerEmail = contactEmail;
    const surcharge = 0;
    notifyEmails.push(contactEmail);

    //console.log(selectedInvoices);

    try {
      const paymentStatus = await execBankPayment(
        firebase,
        account,
        payerEmail,
        notifyEmails,
        selectedMethod,
        paymentDate,
        selectedInvoices,
        paySubtotal,
        surcharge,
        Math.abs(creditsApplied)
      );

      await firebase.updatePaymentMethodLastUsed(
        account.path,
        selectedMethod.id
      );

      handlePaymentCreated(
        selectedMethod.type,
        paySubtotal,
        surcharge,
        Math.abs(creditsApplied),
        selectedInvoices,
        paymentStatus
      );
    } catch (err) {
      //console.error(err);
      let msg;
      if (
        /failed to fetch/i.test(err.message) ||
        /internal/i.test(err.message)
      ) {
        msg = Strings.ERROR_PAYMENT_REQUEST_FAILED;
      } else {
        msg = err.message;
      }
      toast.error(msg);
      handlePayProgressChange(false);
    }
  };

  const [bankLinkVerifyToken, setBankLinkVerifyToken] = useState(null);
  const createLinkVerifyToken = async () => {
    //console.log("createLinkVerifyToken");
    handleForceSelectedMethodUpdate(true);

    try {
      const { data } = await firebase.createBankLinkVerifyToken(
        account.customer,
        account.id,
        selectedMethod.processor_access_token
      );

      setBankLinkVerifyToken(data);
    } catch (err) {
      //console.error(err);
      toast.error(
        "There was an issue verifying your account. Try re-adding your bank with routing & account numbers"
      );
      handleForceSelectedMethodUpdate(false);
    }
  };
  if (!bankMethods || !selectedMethod || selectedMethod.type !== "bank")
    return null;

  if (bankLinkVerifyToken)
    return (
      <>
        <InlineLoading />
        <PlaidLink
          token={bankLinkVerifyToken}
          account={account}
          contactEmail={contactEmail}
          bankMethod={selectedMethod}
          callback={() => setBankLinkVerifyToken(null)}
        />
      </>
    );

  if (
    ["pending_automatic_verification", "pending_manual_verification"].includes(
      selectedMethod.status
    )
  )
    return (
      <>
        <CRow className="align-items-center my-2">
          <CCol xs="auto" className="pr-0">
            <span className="bg-light border-light rounded-lg p-2 text-dark">
              <CIcon
                title="bank-icon"
                content={freeSet.cilBank}
                alt="bank-icon"
                size={"xl"}
                className="p-1"
              />
            </span>
          </CCol>
          <CCol>
            <CRow>
              <CCol>
                {
                  {
                    pending_automatic_verification: (
                      <>
                        <p className="font-weight-bold mb-0">
                          {formatBankName(selectedMethod)}
                        </p>
                        <p className="mb-0">
                          Microdeposit auto-verification in progress
                        </p>
                      </>
                    ),
                    pending_manual_verification: (
                      <>
                        <p className="font-weight-bold mb-0">
                          Microdeposit verification in progress
                        </p>
                        <p className="mb-0">
                          <span>Have your 3-letter code?</span>{" "}
                          <CButton
                            color="link"
                            className="p-0 border-0"
                            onClick={createLinkVerifyToken}
                          >
                            Verify
                          </CButton>
                        </p>
                      </>
                    ),
                  }[selectedMethod.status]
                }
              </CCol>
              <CCol xs="auto">
                <CButton
                  color="primary"
                  variant="outline"
                  shape="pill"
                  onClick={handleMethodChange}
                  className="align-self-center font-weight-bold"
                >
                  Change Payment Method
                </CButton>
              </CCol>
            </CRow>
          </CCol>
        </CRow>
      </>
    );

  return (
    <>
      <CRow className="mt-2 mb-3 align-items-center">
        <CCol
          xs="auto"
          className="p-2 ml-3 bg-light border-light d-none d-sm-flex rounded-lg"
        >
          <CIcon
            title="bank-icon"
            content={freeSet.cilBank}
            alt="bank-icon"
            size={"2xl"}
            className="p-1"
          />
        </CCol>
        <CCol>
          <CRow className="justify-content-between">
            {selectedMethod.status === "requires_link_update" ? (
              <>
                <CCol>
                  <p className="font-weight-bold mb-0">
                    {formatBankName(selectedMethod)}
                  </p>
                  <p className="mb-0 d-flex align-items-baseline">
                    <span className="mr-2">Unable to authenticate</span>{" "}
                    <CButton
                      color="link"
                      className="p-0 border-0"
                      onClick={createLinkVerifyToken}
                    >
                      Reconnect
                    </CButton>
                  </p>
                </CCol>
              </>
            ) : (
              <CCol>
                <p className="mb-0">Pay with</p>
                <p className="font-weight-bold mb-0">
                  {formatBankName(selectedMethod)}
                </p>
              </CCol>
            )}
            {!showConfirmation && (
              <CCol xs="auto">
                <CButton
                  color="primary"
                  variant="outline"
                  shape="pill"
                  onClick={handleMethodChange}
                  className="align-self-center font-weight-bold"
                >
                  Change Payment Method
                </CButton>
              </CCol>
            )}
          </CRow>
        </CCol>
      </CRow>
      {!showConfirmation && (
        <CRow>
          <CCol>
            <PayButton
              cta={isFuturePayment(paymentDate) ? "Schedule Payment:" : "Pay"}
              amount={(paySubtotal - creditsApplied) / 100}
              isLoading={!selectedMethod.processor_access_token || isPaying}
              isDisabled={paySubtotal - creditsApplied <= 0}
              handlePayment={
                isBackOfficePayment
                  ? () => setShowConfirmation(true)
                  : submitBankPayment
              }
            />
          </CCol>
        </CRow>
      )}
      {isBackOfficePayment && showConfirmation && (
        <CRow>
          <CCol className="pt-1 d-none d-sm-block">
            <CButton
              color="link"
              className="font-weight-bold w-100 btn-block"
              onClick={() => setShowConfirmation(false)}
            >
              Cancel
            </CButton>
          </CCol>
          <CCol className="pt-1">
            <PayButton
              cta="Confirm Payment"
              amount={(paySubtotal - creditsApplied) / 100}
              isLoading={isPaying}
              isBlockBtn={true}
              handlePayment={submitBankPayment}
            />
          </CCol>
        </CRow>
      )}
    </>
  );
};

export const CardPaymentMethod = ({
  account,
  selectedInvoices,
  paySubtotal,
  creditsApplied,
  paymentDate,
  methods,
  selectedMethod,
  contactEmail,
  notifyEmails,
  isPaying,
  isBackOfficePayment,
  hasWaivedSurcharge,
  handlePayProgressChange,
  handleMethodChange,
  handlePaymentCreated,
}) => {
  const firebase = useContext(FirebaseContext);

  const [showConfirmation, setShowConfirmation] = useState(false);

  /**
   * Card Payment Surcharge
   * ~~~~~~~~~~~~~~~~~~~~~~~
   *
   * */

  const [surchargeRate, setSurchargeRate] = useState(null);
  useEffect(() => {
    if (!account) return;
    setSurchargeRate(getCardSurchargeRate(account.customer));
  }, [account]);

  const [surchargeAmount, setSurchargeAmount] = useState(null);
  useEffect(() => {
    if (!surchargeRate || typeof paySubtotal !== "number") return;

    const paymentAmount = (paySubtotal - creditsApplied) / 100;
    //console.log(paymentAmount, paySubtotal);
    if (hasWaivedSurcharge) setSurchargeAmount(0);
    else if (paymentAmount < 0) setSurchargeAmount(0);
    else {
      setSurchargeAmount(paymentAmount * surchargeRate);
    }
  }, [surchargeRate, paySubtotal, creditsApplied, hasWaivedSurcharge]);

  const [paymentSurchargeMsg, setPaymentSurchargeMsg] = useState("");
  useEffect(() => {
    if (!account || !surchargeRate) return;
    setPaymentSurchargeMsg(
      `${surchargeRate * 100}${Strings.PAYMENT_CARD_SURCHARGE_WARNING_SUFFIX}`
    );
  }, [account, surchargeRate]);

  /**
   * Submit Card Payment
   * ~~~~~~~~~~~~~~~~~~~~~
   *
   *
   *
   * */

  const doCardPayment = async () => {
    handlePayProgressChange(true);
    setShowConfirmation(false);

    const payerEmail = contactEmail;
    const surcharge = convertToCents(surchargeAmount);
    notifyEmails.push(contactEmail);

    try {
      const paymentStatus = await execCardPayment(
        firebase,
        account,
        payerEmail,
        notifyEmails,
        selectedMethod,
        paymentDate,
        selectedInvoices,
        paySubtotal,
        surcharge,
        Math.abs(creditsApplied)
      );

      firebase.updatePaymentMethodLastUsed(account.path, selectedMethod.id);

      handlePaymentCreated(
        selectedMethod.type,
        paySubtotal,
        surcharge,
        Math.abs(creditsApplied),
        selectedInvoices,
        paymentStatus
      );
    } catch (err) {
      //console.error(err);
      let msg;
      if (
        /failed to fetch/i.test(err.message) ||
        /internal/i.test(err.message)
      ) {
        msg = Strings.ERROR_PAYMENT_REQUEST_FAILED;
      } else {
        msg = err.message;
      }
      toast.error(msg);
      handlePayProgressChange(false);
    }
  };

  if (!selectedMethod) return <InlineLoading />;

  return (
    <>
      <CRow className="my-3" sm="auto">
        <CCol>
          <CRow className="justify-content-between">
            <CCol xs="auto">
              {selectedMethod.brand && (
                <CIcon
                  title={`${selectedMethod.brand.toLowerCase()}-card-icon`}
                  alt={`${selectedMethod.brand.toLowerCase()}-card-icon`}
                  content={cardIcons[selectedMethod.brand.toUpperCase()]}
                  size="2xl"
                />
              )}
              &nbsp;
              <span className="text-nowrap">{`···· ${selectedMethod.last4} ${selectedMethod.exp_month} / ${selectedMethod.exp_year}`}</span>
            </CCol>
            {!showConfirmation && (
              <CCol className="text-right">
                <CButton
                  color="primary"
                  variant="outline"
                  shape="pill"
                  onClick={handleMethodChange}
                  className="align-self-center font-weight-bold text-nowrap"
                >
                  Change Payment Method
                </CButton>
              </CCol>
            )}
          </CRow>
        </CCol>
      </CRow>

      {!showConfirmation && (
        <>
          <CRow className="mt-2 mb-3 text-center">
            <CCol>
              <InlineNotification message={paymentSurchargeMsg} type="muted" />
            </CCol>
          </CRow>
          <CRow>
            <CCol>
              <PayButton
                cta={isFuturePayment(paymentDate) ? "Schedule Payment:" : "Pay"}
                amount={(paySubtotal - creditsApplied) / 100}
                surcharge={surchargeAmount}
                isLoading={isPaying}
                isDisabled={paySubtotal - creditsApplied <= 0}
                handlePayment={() => setShowConfirmation(true)}
              />
            </CCol>
          </CRow>
        </>
      )}
      {isBackOfficePayment && showConfirmation && (
        <CRow>
          <CCol className="pt-1 d-none d-sm-block">
            <CButton
              color="link"
              className="font-weight-bold w-100 btn-block"
              onClick={() => setShowConfirmation(false)}
            >
              Cancel
            </CButton>
          </CCol>
          <CCol className="pt-1">
            <SubmitBtn
              cta="Confirm Payment"
              callback={doCardPayment}
              isLoading={isPaying}
              isBlockBtn={true}
            />
          </CCol>
        </CRow>
      )}
      {!isBackOfficePayment && showConfirmation && (
        <ConfirmationDialog
          isOpen={showConfirmation && !isBackOfficePayment}
          header="Accept Surcharge?"
          body={`Card charges incur a ${
            surchargeRate * 100
          }% fee. Are you sure you want to continue?`}
          primaryCtaText="Accept & Continue"
          handleConfirm={doCardPayment}
          handleClose={() => setShowConfirmation(false)}
        />
      )}
    </>
  );
};

export const CreditsPaymentMethod = ({
  account,
  selectedInvoices,
  paySubtotal,
  creditsApplied,
  paymentDate,
  contactEmail,
  notifyEmails,
  isPaying,
  handlePayProgressChange,
  handlePaymentCreated,
}) => {
  const firebase = useContext(FirebaseContext);

  const handlePayment = async () => {
    handlePayProgressChange(true);

    const surcharge = 0;
    const paymentMethodType = PAYMENT_METHOD_TYPES.CREDITS;
    notifyEmails.push(contactEmail);

    try {
      const paymentStatus = await execCreditsPayment(
        firebase,
        account,
        contactEmail,
        notifyEmails,
        paymentDate,
        selectedInvoices,
        paySubtotal,
        surcharge,
        Math.abs(creditsApplied)
      );

      handlePaymentCreated(
        paymentMethodType,
        paySubtotal,
        surcharge,
        Math.abs(creditsApplied),
        selectedInvoices,
        paymentStatus
      );
    } catch (err) {
      //console.error("Error processing credits only payment", err);
      toast.error(Strings.ERROR_CREDITS_PAYMENT_UNSUCCESSFUL);
    }
  };

  return (
    <>
      <CRow>
        <CCol>
          <PayButton
            cta={
              isFuturePayment(paymentDate)
                ? "Schedule payment with Credit Balance"
                : "Pay with Credit Balance"
            }
            amount={creditsApplied / 100}
            isLoading={isPaying}
            handlePayment={handlePayment}
          />
        </CCol>
      </CRow>
    </>
  );
};

export const AutopaySelectionModal = ({
  isOpen,
  isUpdating,
  paymentMethod,
  autopayType,
  autopayDay,
  handleAutopayTypeChange,
  handleAutopayDayChange,
  handleDismissed,
  handleConfirm,
}) => {
  const [
    isConfirmingAutopaySettings,
    setIsConfirmingAutopaySettings,
  ] = useState(false);
  useEffect(() => {
    setIsConfirmingAutopaySettings(false);
  }, []);

  const [message, setMessage] = useState("");
  useEffect(() => {
    let msg;
    if (!isOpen || !autopayType) msg = "";
    else if (autopayType === "due_date")
      msg = Strings.AUTOPAY_DUEDATE_DESCRIPTION;
    else if (autopayType === "weekly")
      msg = `${Strings.AUTOPAY_WEEKLY_DESCRIPTION} ${autopayDay}s`;
    else msg = "";

    setMessage(msg);
  }, [isOpen, autopayType, autopayDay]);

  const [paymentMethodType, setPaymentMethodType] = useState(null);
  useEffect(() => {
    if (paymentMethod) {
      setPaymentMethodType(paymentMethod.type);
    } else {
      setPaymentMethodType(null);
    }
  }, [paymentMethod]);

  const BankMethodDescription = () => {
    return (
      <CRow className="my-2 align-items-center">
        <CCol
          xs="auto"
          className="p-1 ml-3 bg-light border-light d-none d-sm-flex rounded-lg"
        >
          <CIcon
            title="autopay-bank-icon"
            content={freeSet.cilBank}
            alt="bank-icon"
            size={"xl"}
            className="p-1"
          />
        </CCol>
        <CCol>
          <p className="font-weight-bold mb-0">
            {formatBankName(paymentMethod)}
          </p>
        </CCol>
      </CRow>
    );
  };

  const CardMethodDescription = () => {
    return (
      <CRow className="my-2" sm="auto">
        <CCol>
          <CRow className="justify-content-between">
            <CCol xs="auto">
              {paymentMethod.brand && (
                <CIcon
                  title={`autopay-${paymentMethod.brand.toLowerCase()}-card-icon`}
                  alt={`autopay-${paymentMethod.brand.toLowerCase()}-card-icon`}
                  content={cardIcons[paymentMethod.brand.toUpperCase()]}
                  size="2xl"
                />
              )}
              &nbsp;
              <span className="text-nowrap">{`···· ${paymentMethod.last4} ${paymentMethod.exp_month} / ${paymentMethod.exp_year}`}</span>
            </CCol>
          </CRow>
        </CCol>
      </CRow>
    );
  };

  const PaymentMethodDescription = () => {
    if (!paymentMethod || !paymentMethodType) return null;

    if (paymentMethodType === "bank") return <BankMethodDescription />;

    if (paymentMethodType === "card") return <CardMethodDescription />;

    return null;
  };

  return (
    <CModal
      centered={true}
      show={isOpen}
      onClose={handleDismissed}
      data-testid={"toggle-autopay-on-dialog"}
    >
      <CModalBody>
        <CRow className="my-2">
          <CCol>
            <h5 className="mb-4 font-weight-bold">Set Up Autopay</h5>
            <CRow>
              <CCol xs="7">
                <CFormGroup variant="custom-radio">
                  <CInputRadio
                    custom
                    id="radio-pay-duedate"
                    name="radio-pay-duedate"
                    value="due_date"
                    checked={autopayType === "due_date"}
                    onChange={(e) => handleAutopayTypeChange(e.target.value)}
                  />
                  <CLabel
                    variant="custom-checkbox"
                    htmlFor="radio-pay-duedate"
                    className={
                      autopayType === "due_date" ? "font-weight-bold" : ""
                    }
                  >
                    Pay on Due Date
                  </CLabel>
                </CFormGroup>
              </CCol>
            </CRow>
            <CRow className="align-items-baseline mt-2">
              <CCol xs="auto" className="pr-0">
                <CFormGroup variant="custom-radio">
                  <CInputRadio
                    custom
                    id="radio-pay-weekly"
                    name="radio-pay-weekly"
                    value="weekly"
                    checked={autopayType === "weekly"}
                    onChange={(e) => handleAutopayTypeChange(e.target.value)}
                  />
                  <CLabel
                    variant="custom-checkbox"
                    htmlFor="radio-pay-weekly"
                    className={
                      autopayType === "weekly" ? "font-weight-bold" : ""
                    }
                  >
                    Pay Weekly on
                  </CLabel>
                </CFormGroup>
              </CCol>
              <CCol xs="auto">
                <select
                  className="custom-select"
                  aria-label="autopay-weekday-selector"
                  value={autopayDay}
                  onChange={(e) =>
                    handleAutopayDayChange(parseInt(e.target.value))
                  }
                  disabled={autopayType !== "weekly"}
                >
                  {Info.weekdays().map((v, i) => (
                    <option value={i + 1} key={i}>
                      {v}
                    </option>
                  ))}
                </select>
              </CCol>
            </CRow>
          </CCol>
        </CRow>
        <CRow>
          <CCol className="px-0">
            <hr />
          </CCol>
        </CRow>
        <CRow>
          <CCol>
            <p className="font-weight-bold mb-0 pb-0">Pay with</p>
          </CCol>
        </CRow>
        <PaymentMethodDescription />
      </CModalBody>
      <CModalFooter>
        <CRow>
          <CCol className="pr-0">
            <CButton
              data-testid={"autopay-selection-cancel-btn"}
              color="link"
              onClick={handleDismissed}
            >
              Cancel
            </CButton>
          </CCol>
          <CCol xs="auto">
            {
              {
                false: (
                  <CButton
                    data-testid={"autopay-selection-confirmation-btn"}
                    color="primary"
                    shape="pill"
                    className="font-weight-bold"
                    onClick={handleConfirm}
                  >
                    Confirm
                  </CButton>
                ),
                true: (
                  <CButton
                    data-testid={"autopay-selection-confirmation-btn-loading"}
                    color="primary"
                    shape="pill"
                    className="font-weight-bold"
                  >
                    <span
                      className="spinner-border spinner-border-sm"
                      role="status"
                      aria-hidden="true"
                    ></span>
                  </CButton>
                ),
              }[isUpdating]
            }
          </CCol>
        </CRow>
      </CModalFooter>
    </CModal>
  );
};
